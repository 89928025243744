export const gqlBody = {
  //Lead List query
  getAllLeads(payload) {
    // Create a new object called updatedData and copy all properties from the payload into it.
    // Check if both registerStartDate and registerEndDate properties exist in the payload.
    // Set registerAllTime to false since we have specific start and end dates for registration.
    const updatedData = {
      ...payload,
      ...(payload?.registerStartDate || payload?.registerEndDate
        ? {
            registerStartDate: payload?.registerStartDate,
            registerEndDate: payload?.registerEndDate,
            registerAllTime: false,
          }
        : { registerAllTime: true }),
      ...(payload?.closeStartDate || payload?.closeEndDate
        ? {
            closeStartDate: payload?.closeStartDate,
            closeEndDate: payload?.closeEndDate,
            closeAllTime: false,
          }
        : { closeAllTime: true }),
      ...(payload?.messageStartDate || payload?.messageEndDate
        ? {
            messageStartDate: payload?.messageStartDate,
            messageEndDate: payload?.messageEndDate,
          }
        : {}),
    };
    return {
      query: `query LeadList($input: LeadListInput!) {
        leadList(input: $input) {
          response {
            _id
            contactId
            firstName
            lastName
            registerDate
            email
            phone
            description
            birthDate
            birthMonth
            birthYear
            dripOrder
            callTotal
            emailTotal
            messageTotal
            isLeadStory
            leadContect {
              phones {
                _id
                phone
                callStatus
                smsStatus
              }
              emails {
                _id
                email
                emailStatus
              }
            }
            tags {
              _id
              title
              isDeleted
              createdAt
              updatedAt
            }
            categories {
              _id
              title
              color
              textColor
              description
              isDeleted
              createdAt
              updatedAt
            }
            agent {
              _id
              name
            }
            lender {
              _id
              name
              financialInstitute
              createdAt
              updatedAt
              email
              photo
              emailVerified
              emailVerificationToken
              role
            }
            todoResponse {
              type
              date
            }
            taskResponse {
              _id
              date
              description
              isCompleted
              toDoId
              agent
              leadId
              errorURL
              errorCode
              errorMessage
              isTime
            }
            dripsResponse {
              _id
              leadId
              dripId
              dripTitle
              dripStatus
              isPlay
              leadDripStatus
              isTerminated
            }
            contact {
              _id
              phone
              callStatus
              smsStatus
            }
            emailStatusDetail {
              _id
              email
              emailStatus
            }
            noteResponse {
            _id
            noteCreator
            comment
            date
            refUsers
            usersName
            isDescription
          }  
            leadType
            address
            city
            state
            zipCode
            isClosed
            closeDate
            anniversaryDate
            closingGift
            socialMediaReview
            leftReview
            requestedReview
            source
            communicationDate
            communicationType
            createdAt
            isAiReply
            lastOpenedEmail
            lastDripId
            lastEmailIncoming
            lastEmailOutgoing
            lastSmsIncoming
            lastSmsOutgoing
            lastCallIncoming
            lastCallOutgoing
            unresponsiveCallCount
            unresponsiveSMSCount
            propensityStatus
          }
          totalCount
          type
          message
          code
        }
      }`,
      variables: {
        input: updatedData,
      },
    };
  },
  addLead(payload) {
    return {
      query: `mutation Mutation($input: AddLeadInput!) {
        addLead(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  editLead(payload) {
    return {
      query: `mutation EditLead($input: EditLeadInput!) {
        editLead(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  deleteLead(payload) {
    return {
      query: `mutation Mutation($input: DeleteLeadInput!) {
        deleteLead(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getAllCategory() {
    return {
      query: `query Query {
        listCategory {
          type
          message
          code
          response {
            _id
            title
            color
            textColor
            description
            isDeleted
            createdAt
            updatedAt
          }
        }
      }`,
      variables: {
        input: {},
      },
    };
  },
  getTagList() {
    return {
      query: `query Query {
        listTag {
          type
          message
          code
          response {
            _id
            title
            isDeleted
            createdAt
            updatedAt
          }
        }
      }`,
      variables: {
        input: {},
      },
    };
  },
  // Tag query
  ListTag() {
    return {
      query: `query ListTag {
        listTag {
          type
          message
          code
          response {
            _id
            title
            isDeleted
            createdAt
            updatedAt
          }
        }
      }`,
    };
  },
  AddTag(payload) {
    return {
      query: `mutation AddTag($input: AddTagInput) {
        addTag(input: $input) {
          code
          message
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getTag(payload) {
    return {
      query: `query Response($input: GetTagInput) {
        getTag(input: $input) {
          response {
            _id
            title
            isDeleted
            createdAt
            updatedAt
          }
          code
          message
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  editTag(payload) {
    return {
      query: `mutation EditTag($input: EditTagInput) {
        editTag(input: $input) {
          code
          message
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  deleteTag(payload) {
    return {
      query: `mutation Mutation($input: DeleteTagInput) {
        deleteTag(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  // Category query
  ListCategory() {
    return {
      query: `query ListCategory {
  listCategory {
    type
    message
    code
    response {
      _id
      title
      color
      textColor
      description
      isDeleted
      createdAt
      updatedAt
      close
    }
  }
}`,
    };
  },
  AddCategory(payload) {
    return {
      query: `mutation AddCategory($input: AddCategoryInput) {
        addCategory(input: $input) {
          code
          message
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  GetCategory(payload) {
    return {
      query: `query GetCategory($input: GetCategoryInput) {
        getCategory(input: $input) {
          type
          message
          code
          response {
            _id
            title
            color
            textColor
            description
            isDeleted
            createdAt
            updatedAt
          }
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  EditCategory(payload) {
    return {
      query: `mutation EditCategory($input: EditCategoryInput) {
        editCategory(input: $input) {
          code
          message
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  DeleteCategory(payload) {
    return {
      query: `mutation DeleteCategory($input: DeleteCategoryInput) {
        deleteCategory(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  sendMail(payload) {
    return {
      query: `mutation SendEmails($input: SendEmailInput!) {
        sendEmails(input: $input) {
          count
          type
          message
          code  
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  sendMessage(payload) {
    return {
      query: `mutation SendSMS($input: SendSMSInput!) {
        sendSMS(input: $input) {
          count
          type
          message
          code
          notSendCount
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  makeCall(payload) {
    return {
      query: `mutation MakeCall($input: MakeCallInput) {
        makeCall(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getLead(payload) {
    return {
      query: `query Lead($input: LeadInput!) {
        lead(input: $input) {
          response {
            leadResponse {
              _id
              contactId
              firstName
              lastName
              registerDate
              email
              phone
              description
              isDeleted
              createdAt
              updatedAt
              leadType
              address
              city
              state
              zipCode
              isAiReply
              source
              isClosed
              closeDate
              anniversaryDate
              closingGift
              leftReview
              socialMediaReview
              requestedReview
              birthDate
              birthMonth
              birthYear
              source
              isAiReply
              story
              isFollowUp
              followUpCount
              lastDripId
              followUpScheduledDateTime
              drips
              followUpMessage
              suggestedDrip
              zillowURL
              propensityStatus
              storyHighlights{
                highlight
                date
              }
              leadSource {
                _id
                title
              }
              contact {
                _id
                phone
                callStatus
                smsStatus
                fromOptedOutNumber
                manuallyOptedOutNo
              }
              emailStatusDetail {
                _id
                email
                emailStatus
                isVerified
              }
            }
            tagResponse {
              _id
              title
              isDeleted
              createdAt
              updatedAt
            }
            categoryResponse {
              _id
              title
              color
              textColor
              description
              isDeleted
              createdAt
              updatedAt
              close
            }
            agentResponse {
              _id
              name
              agentTwilioNumber
            }
            lenderResponse {
              _id
              name
            }
          }
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  editLeadDetails(payload) {
    return {
      query: `mutation EditLead($input: EditLeadInput!) {
        editLead(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  addFilter(payload) {
    return {
      query: `mutation Mutation($input: AddFilterInput!) {
        addFilter(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getFilter(payload) {
    return {
      query: `query Query($input: GetFilterInput!) {
        getFilter(input: $input) {
          response {
            _id
            userId
            columns
          }
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  scheduleDripLeadsInput(payload) {
    return {
      query: `mutation Mutation($input: ScheduleDripLeadsInput!) {
        scheduleDripLeads(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  importLeadCsv(payload) {
    return {
      query: `mutation Mutation($file: Upload!) {
        addLeadCSV(file: $file) {
          type
          message
          code
        }
      }`,
      variables: { file: payload },
    };
  },
  SaveFilter(payload) {
    return {
      query: `mutation SaveFilter($input: saveFilterInput) {
        saveFilter(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  getSaveFilter() {
    return {
      query: `query GetLeadFilters {
        getLeadFilters {
          type
          message
          code
          response {
            _id
            filterName
            userId
            search
            categories
            agent
            lender
            isSelected
            leadType
            includeTags
            excludeTags
            tagType
            communication
            communicationStatus
            registerStartDate
            registerEndDate
            registerAllTime
            taskDate
            taskType
            closeStartDate
            closeEndDate
            closeAllTime
            source
            includeDrips
            excludeDrips
            dripStatus
            filterPhoneStatus,
            filterEmailStatus,
            filterTextStatus,
            filterCommunicationFlag
            optedOutType
            messageStartDate
            messageEndDate
            undeliveredErrorCode
            leadStory
          }
        }
      }`,
    };
  },
  getLeadFilters(payload) {
    return {
      query: `query GetSingleFilter($input: inputLeadFilter) {
        getSingleFilter(input: $input) {
          type
          message
          code
          response {
            _id
            filterName
            userId
            search
            categories
            agent
            lender
            isSelected
            leadType
            includeTags
            excludeTags
            tagType
            communication
            communicationStatus
            registerStartDate
            registerEndDate
            registerAllTime
            taskDate
            taskType
            closeStartDate
            closeEndDate
            closeAllTime
            source
            dripStatus
            includeDrips
            excludeDrips
            filterPhoneStatus
            filterEmailStatus
            filterTextStatus
            filterCommunicationFlag
            messageStartDate
            messageEndDate
            undeliveredErrorCode
            leadStory
          }
        }
      }`,
      variables: { input: payload },
    };
  },
  deleteSaveFilter(payload) {
    return {
      query: `query DeleteFilter($input: inputLeadFilter) {
        deleteFilter(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },

  leadNavigation(payload) {
    return {
      query: `query LeadDetailsBasedOnFilter($input: LeadDetailsBasedOnFilterInput!) {
        leadDetailsBasedOnFilter(input: $input) {
          response {
            leadResponse {
              _id
              contactId
              firstName
              lastName
              email
              phone
              description
              isDeleted
              createdAt
              updatedAt
              leadType
              address
              city
              state
              zipCode
              isAiReply
              source
              isClosed
              closeDate
              anniversaryDate
              closingGift
              leftReview
              socialMediaReview
              requestedReview
              birthDate
              birthMonth
              birthYear
              leadSource {
                _id
                title
              }
            }
            tagResponse {
              _id
              title
              isDeleted
              createdAt
              updatedAt
            }
            categoryResponse {
              _id
              title
              color
              textColor
              description
              isDeleted
              createdAt
              updatedAt
              close
            }
            agentResponse {
              _id
              name
            }
            lenderResponse {
              _id
              name
            }
          }
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  closeLead(payload) {
    return {
      query: `mutation CloseLead($input: CloseLeadInput) {
        closeLead(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  resetLeadFilter() {
    return {
      query: `query ResetLeadFilter {
        resetLeadFilter {
          type
          message
          code
        }
      }`,
    };
  },
  editLeadFilter(payload) {
    return {
      query: `mutation UpdateLeadFilter($input: updateLeadFilterInput) {
        updateLeadFilter(input: $input) {
          type
          message
          code
          response {
            _id
            filterName
            userId
            search
            categories
            agent
            lender
            isSelected
            leadType
            includeTags
            excludeTags
            tagType
            communication
            communicationStatus
            registerStartDate
            registerEndDate
            registerAllTime
            taskDate
            taskType
            closeStartDate
            closeEndDate
            closeAllTime
            messageStartDate
            messageEndDate
            undeliveredErrorCode
          }
        }
      }`,
      variables: { input: payload },
    };
  },
  generateOpenAISMSResponse(payload) {
    return {
      query: `mutation GenerateOpenAIResponse($input: generateResponseInput) {
        generateOpenAIResponse(input: $input) {
          type
          message
          code
          response
        }
      }`,
      variables: { input: payload },
    };
  },
  toggleOpenAIAutomation(payload) {
    return {
      query: `mutation EditLead($input: isAiReplyInput) {
        toggleOpenAIAutomation(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  leadBirthday(payload) {
    return {
      query: `mutation LeadBirthDay($input: LeadBirthDayInput) {
        leadBirthDay(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  leadPlayPause(payload) {
    return {
      query: `mutation LeadPlayOrPause($input: LeadPlayOrPauseInput) {
        leadPlayOrPause(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  LeadSource() {
    return {
      query: `query LeadSource {
        leadSource {
          response {
            _id
            title
          }
          type
          message
          code
        }
      }`,
    };
  },
  phoneStatusUpdate(payload) {
    return {
      query: `mutation PhoneStatusUpdate($input: PhoneStatusUpdateInput) {
        phoneStatusUpdate(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  emailStatusUpdate(payload) {
    return {
      query: `mutation EmailStatusUpdate($input: EmailStatusUpdateInput) {
        emailStatusUpdate(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  bulkAddAgent(payload) {
    return {
      query: `mutation BulkAddAgent($input: BulkAddAgentInput) {
        bulkAddAgent(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  bulkAddLender(payload) {
    return {
      query: `mutation BulkAddLender($input: BulkAddLenderInput) {
        bulkAddLender(input: $input) {
          type
          code
          message
        }
      }`,
      variables: { input: payload },
    };
  },
  checkScheduleLeadCount(payload) {
    return {
      query: `query checkLeadCount($input: CheckLeadCountInput) {
        checkLeadCount(input: $input) {
          leadCount
        }
      }`,
      variables: { input: payload },
    };
  },
  saveLeadDetailsSegments(payload) {
    return {
      query: `mutation SaveLeadSegment($input: SaveLeadSegmentInput) {
        saveLeadSegment(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  getLeadDetailsSegment(payload) {
    return {
      query: `query GetLeadSegment($input: GetLeadSegmentInput) {
       getLeadSegment(input: $input) {
          code
          message
          type
          leadSegment {
            container
            name
          }
        }
      }`,
      variables: { input: payload },
    };
  },
  NextDripPreview(payload) {
    return {
      query: `mutation NextDripPreview($input: NextDripPreviewInput) {
        nextDripPreview(input: $input) {
          code
          message
          preview
          type
          subject
        }
      }`,
      variables: { input: payload },
    };
  },
  AddDripPreview(payload) {
    return {
      query: `mutation AddDripPreview($input: AddDripPreviewInput) {
        addDripPreview(input: $input) {
          preview
          type
          message
          subject
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  GenerateOpenAIDripResponse(payload) {
    return {
      query: `mutation GenerateOpenAIDripResponse($input: GenerateOpenAIDripResponseInput) {
        generateOpenAIDripResponse(input: $input) {
          type
          message
          code
          response
          subject
        }
      }`,
      variables: { input: payload },
    };
  },
  leadStoryPrompt(payload) {
    return {
      query: `mutation LeadStory($input: LeadStoryInput) {
        leadStory(input: $input) {
          type
          message
          code
          story
        }
      }`,
      variables: { input: payload },
    };
  },
  TwilioErrorCode(payload) {
    return {
      query: `query TwilioErrorCode {
        twilioErrorCode {
          response
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  ScheduleDripLeadTask(payload) {
    return {
      query: `mutation ScheduleDripLeadTask($input: ScheduleDripLeadTaskInput) {
        scheduleDripLeadTask(input: $input) {
          code
          message
          type
        }
      }`,
      variables: { input: payload },
    };
  },
  LeadFollowUp(payload) {
    return {
      query: `mutation LeadFollowUp($input: LeadFollowUpInput) {
      leadFollowUp(input: $input) {
        code
        message
        type
      }
    }`,
      variables: { input: payload },
    };
  },
  SuggestDrip(payload) {
    return {
      query: `mutation SuggestDrip($input: suggestDripInput) {
        suggestDrip(input: $input) {
          code
          message
          type
          suggestedDripDescription
          suggestedDripId
          suggestedDripTitle
        }
      }`,
      variables: { input: payload },
    };
  },
  mergeLead(payload) {
    return {
      query: `mutation MergeLead($input: MergeLeadInput) {
  mergeLead(input: $input) {
    type
    message
    code
  }
}`,
      variables: { input: payload },
    };
  },
  MergeLeadCountAndRunningDrips(payload) {
    return {
      query: `query MergeLeadCountAndRunningDrips($input: MergeLeadCountAndDripsInput) {
        mergeLeadCountAndRunningDrips(input: $input) {
          type
          message
          code
          count {
            EAlertCount
            dripCount
            noteCount
            callCount
            smsCount
            taskCount
            emailCount
          }
          drips {
            _id
            leadId
            dripId
            dripTitle
            dripStatus
            isPlay
            leadDripStatus
            isTerminated
            dripSortOrder
          }
        }
      }`,
      variables: { input: payload },
    };
  },
  LeadFollowUPMessageGenerate(payload) {
    return {
      query: `query LeadFollowUPMessageGenerate($input: LeadFollowUPMessageGenerateInput) {
        leadFollowUPMessageGenerate(input: $input) {
          followUpMessage
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  LeadFollowUPMessageEdit(payload) {
    return {
      query: `mutation LeadFollowUPMessageEdit($input: LeadFollowUPMessageEditInput) {
        leadFollowUPMessageEdit(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  DomainList() {
    return {
      query: `query DomainList {
      domainList {
      domains
      type
      message
      code
      }
      }`,
    };
  },
  VerifyEmail(payload) {
    return {
      query: `mutation VerifyEmail($input: verifyEmailInput) {
        verifyEmail(input: $input) {
          code
          message
          type
        }
      }`,
      variables: { input: payload },
    };
  },
  UpdateTempLeadFilter(payload) {
    return {
      query: `mutation UpdateTempLeadFilter($input: updateTempLeadFilterInput) {
        updateTempLeadFilter(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  GmailTokenRevokePopup(payload) {
    return {
      query: `mutation GmailTokenRevokePopup($input: GmailTokenRevokePopupInput) {
        gmailTokenRevokePopup(input: $input) {
          code
          message
          type
        }
      }`,
      variables: { input: payload },
    }
  },
  GetColumnOrder(payload) {
    return {
      query: `query GetColumnOrder($input: GetFilterInput!) {
  getColumnOrder(input: $input) {
    response {
      _id
      userId
      columns {
        index
        colId
        width
      }
    }
    type
    message
    code
  }
}`,
      variables: { input: payload },
    };
  },
  AddColumnOrder(payload) {
    return {
      query: `mutation AddColumnOrder($input: AddColumnOrderInput) {
        addColumnOrder(input: $input) {
           type
           message
           code
        }
      }`,
      variables: { input: payload },
    };
  },
  quickReply(payload) {
    return {
      query: `mutation QuickReply($input: quickReplyInput) {
        quickReply(input: $input) {
          code
          message
          type
        }
      }`,
      variables: { input: payload },
    };
  },

};
