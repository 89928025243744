import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import { handleModalClose } from "Modules/util";
import { Fragment } from "react";
import AddPropertyFilter from "./AddPropertyFilter";

export default function PropertyFilterModal(props) {
  const { isOpen, setIsOpen, lead, setIsRefetchPropertySearch } = props;
  useEscapeClose(setIsOpen, false, handleModalClose); // Custom hook to close a modal when the Escape key is pressed.

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl border-b-2 py-2 transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5">
                  <div className="mt-1 border-b-2 py-2 px-2 sm:mt-0 flex justify-between items-center">
                    <div className="sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                      >
                        Add Property Search
                      </Dialog.Title>
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-5">
                    <AddPropertyFilter
                      lead={lead}
                      setIsOpen={setIsOpen}
                      setIsRefetchPropertySearch={setIsRefetchPropertySearch}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
