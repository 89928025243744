import Skeleton from "Components/Common/Fields/Skeleton";
import { isValidArray } from "Modules/util";
import React, { useEffect, useState } from "react";
import { useGetPropertyVisitsMutation } from "Redux/Leads/PropertySearch/propertySearch";
import notFound from "Assets/icons/notFound.svg";
import PropertyCard from "./PropertyCard";

export default function PropertyVisit({ lead, canDrop }) {
  // State variables
  const [propertyList, setPropertyList] = useState([]); // List of properties
  const [isPropertyLoading, setIsPropertyLoading] = useState(false); // Loading state for properties
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    leadId: lead?.leadResponse?.[0]?._id,
  }); // Lead ID to fetch properties for
  const [totalRecords, setTotalRecords] = useState(100); // Total number of records available
  const [getProperty, { isLoading }] = useGetPropertyVisitsMutation(); // API hook to fetch property visits

  // Function to handle API call for fetching properties
  const handleProperty = async (filter) => {
    try {
      setIsPropertyLoading(true);
      let response = await getProperty(filter);

      const properties =
        response?.data?.data?.getPropertyVisitsByLeadId?.properties;
      const totalCount =
        response?.data?.data?.getPropertyVisitsByLeadId?.totalCount;

      if (isValidArray(properties)) {
        // Append new properties
        setPropertyList((prev) => [...prev, ...properties]);
        setTotalRecords(totalCount); // Update total record count
        setIsPropertyLoading(false);
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
    } finally {
      setIsPropertyLoading(false);
    }
  };

  // Infinite scroll handler
  const handleOnScroll = async (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target; // Access scroll properties correctly
    // Check if scrolled to the bottom and more records are available
    if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
      setFilter((filter) => ({
        ...filter,
        page: filter?.page + 1,
      })); // Update page in filter
      const currentRecord = filter?.page * filter?.limit;
      if (totalRecords >= currentRecord) {
        handleProperty({
          ...filter,
          page: filter?.page + 1,
          leadId: lead?.leadResponse?.[0]?._id,
        });
      }
    }
  };

  // Effect to fetch properties when lead ID changes
  useEffect(() => {
    if (lead?.leadResponse?.[0]?._id) {
      const initialFilter = {
        page: 1,
        limit: 10,
        leadId: lead?.leadResponse?.[0]?._id,
      };

      setFilter(initialFilter);
      setTotalRecords(100); // Reset total records
      setPropertyList([]); // Reset property list
      handleProperty(initialFilter); // Fetch initial properties
    }
  }, [lead?.leadResponse?.[0]?._id]);

  return (
    <>
      <div
        className={`leadDeatils-card-body !h-[390px] rounded-b-2xl overflow-y-auto py-2 px-1 ${
          canDrop && "!bg-orange-300 opacity-85"
        }`}
        onScroll={(e) => handleOnScroll(e)} // Attach scroll event handler
      >
        {isValidArray(propertyList) ? (
          propertyList?.map((property) => <PropertyCard data={property} />)
        ) : isPropertyLoading ? (
          <Skeleton counter={3} height={"60px"} className="my-3" />
        ) : (
          <>
            {/* No Property Found  */}
            <div className="flex justify-center pt-[100px]">
              <img
                alt="edit-icon"
                src={notFound}
                className="w-72 grayscale "
                title={"Move Segment"}
              />
            </div>
            <span className="flex justify-center">
              <label className="font-semibold text-gray text-lg">
                No Property Found 😔
              </label>
            </span>
          </>
        )}
      </div>
    </>
  );
}
