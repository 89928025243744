import { forwardRef } from "react";
import { emailCharFormat } from "../formatter";

const InputField = forwardRef(
  (
    {
      formik,
      label = "",
      name = "",
      type = "text",
      id = "",
      onChange = () => {}, // Custom onChange handler (default is an empty function).
      placeholder = "",
      className = "",
      value = "",
      ...props
    },
    ref
  ) => {
    const handleUserEmailChange = (e) => {
      const inputValue = e?.target?.value;
      const trimmedValue = inputValue.trim();

      if (name === "email") {
        if (trimmedValue === "") {
          formik.setFieldValue(name, "");
          formik.setErrors({ [name]: undefined }); // Clear error when input is empty
        } else if (emailCharFormat?.test(trimmedValue)) {
          formik.setFieldValue(name, trimmedValue);
          formik.setErrors({ [name]: undefined }); // Clear error on valid input
        } else {
          formik.setFieldValue(name, trimmedValue); // Allow input even if invalid
          formik.setErrors({ [name]: "Please Enter Valid Value" });
        }
      } else {
        formik.handleChange(e);
      }
    };

    return (
      <div className="mb-2 w-full ">
        {/* Label for the input field */}
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
        <div className="mt-2">
          {/* Input element with Formik integration */}
          <input
            id={id}
            name={name}
            type={type}
            autoComplete="current-username"
            onChange={handleUserEmailChange}
            onBlur={(e) => {
              // Remove extra spaces on blur
              const values = e.target.value;
              const removeSpace = values.replace(/\s+/g, " ").trim();
              formik.handleChange(e);
              formik.setFieldValue(name, removeSpace);
              formik.handleBlur(e);
            }}
            value={formik.values[name] ?? ""}
            placeholder={placeholder}
            className={`${className} zipcode block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#475361] sm:text-sm sm:leading-6 ${
              props?.disabled ? "opacity-50 cursor-not-allowed bg-gray-100" : ""
            } `}
            ref={ref}
            {...props}
          />
          {formik.touched?.[name] && formik.errors?.[name] && (
            <div className="text-red-500 text-sm">{formik?.errors?.[name]}</div>
          )}
        </div>
      </div>
    );
  }
);

export default InputField;
