// Call
export const CallIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17px"
    height="18px"
    viewBox="0 0 52 52"
    enableBackground="new 0 0 52 52"
    xmlSpace="preserve"
    style={{ fill: "currentColor" }}
  >
    <path
      d="M48.5,37.9L42.4,33c-1.4-1.1-3.4-1.2-4.8-0.1l-5.2,3.8c-0.6,0.5-1.5,0.4-2.1-0.2l-7.8-7l-7-7.8
c-0.6-0.6-0.6-1.4-0.2-2.1l3.8-5.2c1.1-1.4,1-3.4-0.1-4.8l-4.9-6.1c-1.5-1.8-4.2-2-5.9-0.3L3,8.4c-0.8,0.8-1.2,1.9-1.2,3
c0.5,10.2,5.1,19.9,11.9,26.7S30.2,49.5,40.4,50c1.1,0.1,2.2-0.4,3-1.2l5.2-5.2C50.5,42.1,50.4,39.3,48.5,37.9z"
    />
  </svg>
);

export const CallingIcon = (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    id="call"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: "currentColor" }}
  >
    <path
      id="secondary"
      d="M17,13a1,1,0,0,1-1-1,4,4,0,0,0-4-4,1,1,0,0,1,0-2,6,6,0,0,1,6,6A1,1,0,0,1,17,13Zm4.93.12A8.32,8.32,0,0,0,22,12,10,10,0,0,0,12,2a8.32,8.32,0,0,0-1.12.07,1,1,0,1,0,.24,2A8.49,8.49,0,0,1,12,4a8,8,0,0,1,8,8,8.49,8.49,0,0,1-.05.88A1,1,0,0,0,20.82,14h.12A1,1,0,0,0,21.93,13.12Z"
    />
    <path
      id="primary"
      d="M18.18,17.53a3,3,0,0,0-1.5-1.89c-.33-.17-.66-.36-1-.55a3,3,0,0,0-4,.79l-1.26,1.8a18.41,18.41,0,0,1-2.21-1.9,18.41,18.41,0,0,1-1.9-2.21l1.8-1.26a3,3,0,0,0,.79-4c-.19-.3-.38-.63-.55-1a3,3,0,0,0-4.29-1.1l-1,.62A2.15,2.15,0,0,0,2.06,9.21a14.48,14.48,0,0,0,2,4.63h0a19.15,19.15,0,0,0,2.71,3.33,19.15,19.15,0,0,0,3.33,2.71h0a14.48,14.48,0,0,0,4.63,2,2.29,2.29,0,0,0,.51.06,2.21,2.21,0,0,0,1.86-1.12l.62-.95A3,3,0,0,0,18.18,17.53Z"
    />
  </svg>
);

export const CallWrong = (
  <svg
    width="20px"
    // height="16px"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    enableBackground="new 0 0 512 512"
    xmlSpace="preserve"
    style={{ fill: "currentColor" }}
  >
    <path d="M328,374.8c-8.2-16.9-18.8-29.2-37.1-21.7l-36.1,13.4c-28.9,13.4-43.3,0-57.8-20.2l-65-147.8c-8.1-16.9-3.9-32.8,14.4-40.3 l50.5-20.2c18.3-7.6,15.4-23.4,7.2-40.3L161,17.1c-8.2-16.9-25-21-43.3-13.5C81,18.7,50.7,42.4,31.1,77.5 c-24,42.9-12,102.6-7.2,127.7c4.8,25.1,21.6,69.1,43.3,114.2c21.7,45.2,40.8,80.7,57.8,100.8c17,20.1,57.8,75.1,108.3,87.4 c41.4,10,86.1,1.6,122.7-13.5c18.3-7.5,18.4-23.4,10.2-40.4L328,374.8z M489.4,137.7L450,98.3l-59.1,59.1l-59.1-59.1l-39.4,39.4 l59.1,59.1L292.4,256l39.4,39.4l59.1-59.1l59.1,59.1l39.4-39.4l-59.1-59.1L489.4,137.7z" />
  </svg>
);
// Email
export const invalidEmailIcon = (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20px"
    viewBox="0 0 330 330"
    style={{ fill: "currentColor" }}
    xmlSpace="preserve"
  >
    <g id="XMLID_104_">
      <path
        id="XMLID_105_"
        d="M256.819,193.18c-5.857-5.857-15.355-5.858-21.213,0.001L225,203.787l-10.606-10.607 c-5.857-5.858-15.355-5.857-21.213-0.001c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.607 c-5.858,5.858-5.858,15.355,0,21.213c2.929,2.929,6.768,4.393,10.606,4.393c3.839,0,7.678-1.464,10.607-4.394L225,246.213 l10.606,10.607c2.929,2.929,6.768,4.394,10.607,4.394c3.838,0,7.678-1.465,10.606-4.393c5.858-5.858,5.858-15.356,0-21.213 L246.213,225l10.606-10.607C262.678,208.535,262.678,199.038,256.819,193.18z"
      />
      <polygon id="XMLID_106_" points="250,0 20,0 60,30 135,86.25  " />
      <path
        id="XMLID_107_"
        d="M270,130.145V22.5L240,45l-96,72c-2.666,2-5.834,3-9,3s-6.334-1-9-3L0,22.5V195c0,8.284,6.716,15,15,15 h106.076c-0.704,4.901-1.076,9.907-1.076,15c0,57.897,47.104,105,105,105s105-47.103,105-105 C330,183.198,305.443,147.027,270,130.145z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z"
      />
    </g>
  </svg>
);

export const validEmailIcon = (
  <svg
    width={"20px"}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: "currentColor" }}
  >
    <path
      className="clr-i-solid clr-i-solid-path-1"
      d="M32.33 6a2 2 0 0 0-.41 0h-28a2 2 0 0 0-.53.08l14.45 14.39Z"
    />
    <path
      className="clr-i-solid clr-i-solid-path-2"
      d="m33.81 7.39-14.56 14.5a2 2 0 0 1-2.82 0L2 7.5a2 2 0 0 0-.07.5v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-.12-.61M5.3 28H3.91v-1.43l7.27-7.21 1.41 1.41Zm26.61 0h-1.4l-7.29-7.23 1.41-1.41 7.27 7.21Z"
    />
    <path fill="none" d="M0 0h36v36H0z" />
  </svg>
);
//  Phone
export const solidSMSPhoneIcon = (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20px"
    viewBox="0 0 344.295 344.295"
    style={{ fill: "currentColor" }}
    xmlSpace="preserve"
  >
    <g>
      <path d="M180.871,191.475v97.567H45.168V46.041H152.61c3.66-4.243,7.837-8.241,12.519-11.936 c12.883-10.167,28.574-17.236,45.635-20.77c-0.801-4.548-4.76-8.006-9.537-8.006H65.686V4.846C65.686,2.17,63.516,0,60.838,0 H41.129c-2.676,0-4.846,2.17-4.846,4.846V5.33H24.811c-5.352,0-9.691,4.34-9.691,9.693v319.578c0,5.353,4.34,9.693,9.691,9.693 h176.416c5.354,0,9.693-4.34,9.693-9.693V184.425C199.221,188.536,188.98,190.547,180.871,191.475z M81.598,19.305h62.844v10.986 H81.598V19.305z M113.02,326.361c-5.889,0-10.662-4.773-10.662-10.662c0-5.889,4.773-10.662,10.662-10.662 c5.889,0,10.662,4.773,10.662,10.662C123.682,321.588,118.908,326.361,113.02,326.361z" />
      <path d="M239.059,20.127c-49.691,0-90.117,32.293-90.117,71.987c0,22.331,13.082,43.485,35.143,57.064 c-0.873,3.888-4.131,12.707-16.268,22.95c-1.246,1.052-1.754,2.74-1.295,4.306c0.459,1.564,1.799,2.711,3.416,2.923 c0.225,0.029,2.3,0.288,5.968,0.288c10.424,0,31.657-2.054,57.574-15.684c1.873,0.091,3.744,0.137,5.579,0.137 c49.691,0,90.117-32.292,90.117-71.984C329.176,52.42,288.749,20.127,239.059,20.127z M198.408,113.934 c-5.805,0-11.857-2.491-15.799-6.503l-2.561-2.604l6.031-6.031l2.604,2.751c2.217,2.343,6.125,3.918,9.725,3.918 c3.816,0,8.273-1.2,8.273-4.584c0.029-3.302-1.918-4.387-8.625-6.418c-6.082-1.843-14.41-4.365-14.41-14.193 c0-7.313,6.418-12.227,15.975-12.227c4.307,0,9,1.303,12.248,3.401l3.049,1.968l-4.453,7.129l-3.141-2.024 c-1.859-1.198-4.955-2.003-7.703-2.003c-3.736,0-7.506,1.161-7.506,3.756c0,3.051,2.014,4.148,8.477,6.102 c6.178,1.867,14.639,4.425,14.561,14.539C215.152,108.688,208.424,113.934,198.408,113.934z M262.527,113.934h-8.463l-0.039-25.849 l-11,21.605h-4.623l-11.035-21.605v25.849h-8.471V68.042h7.871L240.756,95.5l13.895-27.458h7.877V113.934z M281.324,113.934 c-5.803,0-11.855-2.491-15.799-6.503l-2.561-2.604l6.033-6.031l2.602,2.751c2.219,2.343,6.127,3.918,9.725,3.918 c3.818,0,8.275-1.2,8.275-4.584c0.027-3.302-1.918-4.387-8.625-6.418c-6.082-1.843-14.41-4.365-14.41-14.193 c0-7.313,6.418-12.227,15.973-12.227c4.309,0,9.002,1.303,12.25,3.401l3.049,1.968l-4.453,7.129l-3.141-2.024 c-1.859-1.198-4.955-2.003-7.705-2.003c-3.734,0-7.504,1.161-7.504,3.756c0,3.051,2.014,4.148,8.476,6.102 c6.178,1.867,14.639,4.425,14.561,14.539C298.07,108.688,291.342,113.934,281.324,113.934z" />
    </g>
  </svg>
);

export const outlineSMSPhoneIcon = (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20px"
    viewBox="0 0 455 455"
    style={{
      fill: "currentColor",
      stroke: "currentColor",
      strokeWidth: "8px",
    }}
    and="true"
    add="true"
    border="true"
    xmlSpace="preserve"
  >
    <g>
      <path
        strokeWidth="8px"
        d="M263.356,286.243c-4.143,0-7.5,3.358-7.5,7.5v75.572H66.644v-189.22h57.74c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5 h-57.74v-31.768c0-13.188,10.729-23.917,23.918-23.917h10.799c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H90.562 c-21.459,0-38.918,17.458-38.918,38.917v282.755c0,21.459,17.459,38.917,38.918,38.917h141.377 c21.459,0,38.918-17.458,38.918-38.917V293.743C270.856,289.601,267.499,286.243,263.356,286.243z M231.938,440H90.562 c-13.188,0-23.918-10.729-23.918-23.917v-31.768h189.213v31.768C255.856,429.271,245.127,440,231.938,440z"
      />
      <path
        strokeWidth="8px"
        d="M195.566,105.324c-10.906-1.784-11.366-2.85-11.366-5.089c0-3.959,6.645-5.005,10.158-5.005 c5.645,0,9.286,1.902,9.967,3.781c1.411,3.894,5.712,5.909,9.606,4.496c3.895-1.411,5.908-5.712,4.497-9.606 c-3.056-8.433-12.279-13.671-24.07-13.671c-14.813,0-25.158,8.226-25.158,20.005c0,15.976,14.989,18.428,23.944,19.893 c10.913,1.785,11.373,2.853,11.373,5.098c0,3.959-6.646,5.005-10.159,5.005c-5.645,0-9.287-1.903-9.967-3.783 c-1.409-3.896-5.712-5.908-9.604-4.501c-3.895,1.409-5.91,5.709-4.501,9.604c3.053,8.438,12.276,13.68,24.072,13.68 c14.813,0,25.159-8.226,25.159-20.005C219.518,109.242,204.524,106.789,195.566,105.324z"
      />
      <path
        strokeWidth="8px"
        d="M333.562,105.324c-10.906-1.784-11.366-2.85-11.366-5.089c0-3.959,6.645-5.005,10.158-5.005 c5.644,0,9.286,1.903,9.968,3.782c1.411,3.894,5.711,5.91,9.606,4.495c3.895-1.412,5.907-5.713,4.495-9.607 c-3.057-8.432-12.279-13.67-24.069-13.67c-14.813,0-25.158,8.226-25.158,20.005c0,15.976,14.989,18.428,23.944,19.893 c10.913,1.785,11.373,2.853,11.373,5.098c0,3.959-6.646,5.005-10.159,5.005c-5.646,0-9.288-1.904-9.969-3.784 c-1.409-3.894-5.707-5.912-9.604-4.5c-3.895,1.41-5.909,5.71-4.499,9.605c3.054,8.438,12.277,13.679,24.072,13.679 c14.813,0,25.159-8.226,25.159-20.005C357.513,109.242,342.52,106.789,333.562,105.324z"
      />
      <path
        strokeWidth="8px"
        d="M287.29,80.693c-2.949-1.087-6.26-0.221-8.299,2.167l-15.635,18.317l-15.635-18.317c-2.039-2.389-5.353-3.255-8.299-2.167 c-2.947,1.086-4.905,3.895-4.905,7.037v50c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-29.661l8.135,9.53 c1.425,1.669,3.509,2.631,5.704,2.631s4.279-0.961,5.704-2.631l8.135-9.53v29.661c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-50 C292.195,84.588,290.237,81.78,287.29,80.693z"
      />
      <path
        strokeWidth="8px"
        d="M361.711,32.448C335.363,11.523,300.434,0,263.355,0c-37.079,0-72.01,11.524-98.356,32.448 c-26.854,21.328-41.643,49.839-41.643,80.282c0,53.437,45.967,99.009,110.149,110.165c1.168,16.66-4.858,33.198-16.639,45.091 c-2.131,2.151-2.759,5.371-1.594,8.165c1.165,2.793,3.895,4.613,6.922,4.613c34.298,0,63.751-24.484,70.694-57.815 c64.351-11.061,110.467-56.692,110.467-110.219C403.356,82.288,388.566,53.776,361.711,32.448z M285.178,208.966 c-3.906,0.54-6.728,4.01-6.457,7.944c0.002,0.035,0.005,0.069,0.008,0.104c-3.452,22.079-19.215,39.739-39.588,46.15 c7.824-13.859,11.039-30.124,8.82-46.213c0.001-0.015,0.002-0.029,0.003-0.043c0.271-3.933-2.551-7.404-6.456-7.944 c-59.77-8.261-103.151-48.733-103.151-96.233c0-53.889,56.074-97.73,124.999-97.73c68.926,0,125.001,43.841,125.001,97.73 C388.356,160.239,344.964,200.713,285.178,208.966z"
      />
    </g>
  </svg>
);
export const invalidSMSPhoneIcon = (
  <svg
    width="20px"
    id="Icons"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    style={{ fill: "currentColor" }}
  >
    <g>
      <path d="M30.8,18.6c0.3-0.4,0.3-1-0.1-1.3s-0.9-0.4-1.3-0.1c-3.6,2.8-5.1,2.8-8.8,0c-0.4-0.3-1-0.3-1.3,0.1 c-0.4,0.4-0.4,0.9-0.1,1.3c2.8,3.6,2.8,5.1,0,8.8c-0.3,0.4-0.3,1,0.1,1.3c0.4,0.4,0.9,0.4,1.3,0.1c3.6-2.8,5.1-2.8,8.8,0 c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-0.9,0.1-1.3C27.9,23.7,27.9,22.3,30.8,18.6z" />
    </g>
    <path d="M17.6,19.8c-0.9-1.2-0.8-2.9,0.2-4c1.1-1.1,2.8-1.2,4-0.2c2.9,2.3,3.4,2.3,6.3,0c0.3-0.2,0.5-0.3,0.8-0.5V9c0-2.8-2.2-5-5-5 H8C5.2,4,3,6.2,3,9v19c0,0.4,0.2,0.7,0.6,0.9C3.7,29,3.9,29,4,29c0.2,0,0.5-0.1,0.7-0.2c4.1-3.5,9.3-5.5,14.7-5.7 C19.4,22.2,18.8,21.3,17.6,19.8z M14,17h-3c-0.6,0-1-0.4-1-1s0.4-1,1-1h3c0.6,0,1,0.4,1,1S14.6,17,14,17z M11,13c-0.6,0-1-0.4-1-1 s0.4-1,1-1h6c0.6,0,1,0.4,1,1s-0.4,1-1,1H11z" />
  </svg>
);

export const emailIcon = (
  <svg
    width="20px"
    viewBox="0 0 32 32"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.58 19.107 3.89 11.03a3 3 0 0 1 3.22-5.06l9.31 5.924 8.36-5.851a3 3 0 0 1 3.44 4.915Z"
      fill="#ea4435"
    />
    <path
      d="M27.5 26.5h-4v-18a3 3 0 0 1 3-3 3 3 0 0 1 3 3v16a2 2 0 0 1-2 2"
      fill="#00ac47"
    />
    <path
      d="M29.456 8.066c-.009-.06-.008-.122-.02-.182-.02-.091-.055-.176-.083-.265a3 3 0 0 0-.096-.3c-.02-.047-.05-.088-.073-.134a3 3 0 0 0-.215-.385c-.04-.058-.09-.108-.135-.163a3 3 0 0 0-.245-.285c-.067-.064-.142-.118-.215-.177a3 3 0 0 0-.24-.185c-.079-.052-.166-.092-.25-.136-.088-.046-.175-.097-.267-.133-.089-.036-.183-.059-.276-.086s-.186-.06-.281-.078a3 3 0 0 0-.357-.036c-.076-.005-.151-.019-.227-.018a3 3 0 0 0-.422.043c-.056.008-.113.007-.17.019a33 33 0 0 0-.564.178c-.051.022-.096.055-.146.08A2.9 2.9 0 0 0 23.5 8.5v5.762l4.72-3.304a2.89 2.89 0 0 0 1.236-2.893Z"
      fill="#ffba00"
    />
    <path
      d="M5.5 5.5a3 3 0 0 1 3 3v18h-4a2 2 0 0 1-2-2v-16a3 3 0 0 1 3-3"
      fill="#4285f4"
    />
    <path
      d="M2.544 8.066c.009-.06.008-.122.02-.182.02-.091.055-.176.083-.265a3 3 0 0 1 .096-.299c.02-.047.05-.09.073-.135a3 3 0 0 1 .216-.385c.04-.058.09-.108.134-.163a3 3 0 0 1 .245-.285c.067-.064.143-.118.215-.177a3 3 0 0 1 .24-.185c.08-.052.166-.092.25-.136a3 3 0 0 1 .267-.134c.089-.036.184-.059.277-.086s.185-.06.28-.078a3 3 0 0 1 .357-.036c.076-.005.151-.018.228-.018a3 3 0 0 1 .421.043c.057.008.113.008.17.02a3 3 0 0 1 .285.088 3 3 0 0 1 .279.09c.051.021.096.054.146.079a3 3 0 0 1 .375.21A3 3 0 0 1 8.5 8.5v5.762l-4.72-3.304a2.89 2.89 0 0 1-1.236-2.892"
      fill="#c52528"
    />
  </svg>
);

export const zilliowIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="20px">
    <g id="zillow">
      <path
        fill="#006aff"
        d="M94.3 42.5c.6-.1.8.1 1.1.4 1.9 2.1 7.9 9.5 9.6 11.5.3.4.1.8-.2 1C92.6 65 79 78.5 71.5 88.1c-.2.2 0 .2.1.2 13.2-5.7 44.2-14.8 58.2-17.3V53.3L75.1 10.1 20.3 53.3v19.4c16.9-10.1 56.2-25.8 74-30.2z"
      />
      <path
        fill="#006aff"
        d="M49.8 116.9c-.5.2-.8.2-1.2-.2l-10.2-12.2c-.3-.3-.3-.5.1-1 7.9-11.6 24-29.6 34.3-37.2.2-.1.1-.3-.1-.2C61.9 69.6 31.5 82.7 20.3 89v41.3h109.5V90.6c-15 2.5-59.7 15.8-80 26.3z"
      />
    </g>
  </svg>
);

export const userWithStar = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 401.138 401.138">
    <path
      fill="#f97316"
      d="M98.795 226.834c10.987-6.978 18.286-19.227 18.286-33.205 0-21.745-17.628-39.368-39.368-39.368-21.745 0-39.373 17.623-39.373 39.368 0 13.978 7.31 26.227 18.286 33.205C23.959 236.021 0 266.017 0 301.622c0 42.92 155.416 42.92 155.416 0 0-35.605-23.954-65.596-56.621-74.788m299.067-5.7-75.152-75.152V96.417c0-6.173-5.004-11.188-11.194-11.188-6.179 0-11.183 5.015-11.183 11.188v27.195l-53.02-53.009c-4.362-4.368-11.444-4.368-15.8 0l-75.298 75.298a40.1 40.1 0 0 1 14.876 16.752l68.331-68.336 142.645 142.639a11.15 11.15 0 0 0 7.898 3.28c2.85 0 5.727-1.088 7.903-3.28 4.362-4.383 4.362-11.46-.006-15.822"
    />
    <path
      fill="#f97316"
      d="M365.88 233.965 247.336 115.421c-2.105-2.105-4.939-3.285-7.903-3.285s-5.803 1.18-7.903 3.285l-57.235 57.23c.408 2.274.658 4.612.658 7 0 5.118-.968 10.089-2.785 14.68 16.051 11.672 26.173 30.606 26.173 51.225 0 7.19-3.236 23.437-30.47 31.275a93 93 0 0 1 3.383 24.797c0 11.792-5.586 21.033-14.615 28.153h37.638c7.49 0 14.718-3.166 19.831-8.703a26.9 26.9 0 0 0 7.201-18.335v-42.49c0-10.312 7.903-18.923 18.112-19.896 10.209.974 18.112 9.589 18.112 19.896v42.49c0 6.81 2.562 13.32 7.196 18.335a27.04 27.04 0 0 0 19.831 8.703h57.866c14.892 0 27.027-12.124 27.027-27.038v-57.866c0-1.692-.158-3.41-.5-5.118a11.15 11.15 0 0 0-3.073-5.794"
    />
    <path
      fill="#f97316"
      d="M113.921 224.044c17.726 8.284 31.867 22.436 40.418 39.613 15.79-2.633 28.163-8.659 28.163-18.101 0-21.724-14.62-40.026-34.554-45.639 6.701-4.27 11.166-11.738 11.166-20.271 0-13.266-10.764-24.019-24.024-24.019-7.99 0-15.044 3.911-19.412 9.899 5.836 7.87 9.333 17.568 9.333 28.087-.005 11.34-4.003 22.028-11.09 30.431"
    />
  </svg>
);
export const CircleWithDot = (
  <svg
    width="20px"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke="black"
      stroke-width="7"
      fill="none"
    />
    <circle cx="50" cy="50" r="5" fill="black" />
  </svg>
);
