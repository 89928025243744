import SpinnerButton from "Components/Common/Buttons/SpinnerButton";
import Checkbox from "Components/Common/Checkbox/Checkbox";
import InputField from "Components/Common/Fields/InputField";
import SelectField from "Components/Common/Fields/SelectField";
import { homeTypes, propertyCheckboxs } from "Components/Leads/assetData";
import { useFormik } from "formik";
import { bathTypeOptions, bedTypeOptions } from "Modules/data";
import { showSuccessMsg } from "Modules/util";
import { addSavePropertyValidation } from "Modules/validation";
import React from "react";
import { useAddPropertySearchEAlertMutation } from "Redux/Leads/PropertySearch/propertySearch";

export default function AddPropertyFilter({
  lead,
  setIsOpen,
  setIsRefetchPropertySearch,
}) {
  const [addPropertySearch] = useAddPropertySearchEAlertMutation();

  const formik = useFormik({
    initialValues: {
      BusinessOpportunity: false,
      ManufacturedInPark: false,
      ResidentialIncome: false,
      ResidentialLease: false,
      Land: false,
      CommercialLease: false,
      CommercialSale: false,
      Residential: false,
      minPrice: "",
      maxPrice: "",
      bath: "any",
      bed: "any",
    },
    validationSchema: addSavePropertyValidation(),
    onSubmit: async (values) => {
      // Extract selected home types
      const selectedHomeTypes = homeTypes.filter((home) => values[home]);
      // Construct payload
      const payload = {
        leadId: lead?.leadResponse?.[0]?._id || null, // Ensure a default value
        minimum: `${values?.minPrice}` || null, // Default to null if not provided
        maximum: `${values?.maxPrice}` || null, // Default to null if not provided
        bedrooms: values?.bed === "any" ? null : values?.bed,
        bathrooms: values?.bath === "any" ? null : values?.bath,
        homeType: selectedHomeTypes,
      };
      try {
        const response = await addPropertySearch(payload);
        const result = response?.data?.data?.addPropertySearchEAlert;
        if (result?.type === "success") {
          showSuccessMsg(result.message);
          setIsRefetchPropertySearch(true);
          setIsOpen(false);
        } else if (result?.message) {
          showSuccessMsg(result.message);
        }
      } catch (error) {
        console.error("Error adding property search:", error);
      }
    },
  });

  // Key press handler to prevent non-numeric characters
  const handleKeyPress = (e) => {
    const charCode = e.charCode || e.keyCode;
    const char = String.fromCharCode(charCode);

    // Allow numbers and prevent others
    if (!/^[0-9]$/.test(char)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <label className="font-semibold block mb-1 text-gray-700">
        Home Type
      </label>
      <div className="my-2">
        <div className="text-orange-500 space-y-1.5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 my-3">
          {propertyCheckboxs?.map((check, index) => (
            <Checkbox
              key={index}
              id={check?.variable}
              name={check?.variable}
              label={check?.label}
              checked={formik?.values?.[check?.variable]}
              onChange={(e) =>
                formik?.setFieldValue(check?.variable, e.target.checked)
              }
            />
          ))}
        </div>
      </div>
      <div className="border-t-2 mt-4 xs2:flex xs2:items-start">
        <label className="font-semibold mr-10 text-gray-700 mt-5">
          Price Range
        </label>
        <div className="flex flex-col my-2">
          <div className="sm2:flex sm2:items-start sm2:space-x-4">
            <div>
              <div className="w-60 flex items-start">
                <div className="bg-gray-200 w-6 text-center rounded-md shadow-sm !rounded-r-none border border-gray-400 border-r-0 p-1 font-semibold text-gray-500 mt-2">
                  $
                </div>
                <InputField
                  formik={formik}
                  id="minPrice"
                  name="minPrice"
                  type="number"
                  value={formik?.values?.minPrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="minPrice"
                  placeholder="minimum price"
                  className="!ring-0 !border !rounded-l-none shadow-sm !border-gray-400 !border-l-0 !p-1"
                  min={0}
                  onKeyPress={handleKeyPress} // Attach key press handler
                />
              </div>
              <div
                style={{
                  height: !(formik.touched?.minPrice && formik.errors?.minPrice)
                    ? "40px"
                    : "0px",
                }}
              ></div>
            </div>
            <span className="text-gray-500 mt-3">to</span>
            <div>
              <div className="w-60 flex items-start">
                <div className="bg-gray-200 w-6 text-center rounded-md shadow-sm !rounded-r-none border border-gray-400 border-r-0 p-1 font-semibold text-gray-500 mt-2">
                  $
                </div>
                <InputField
                  formik={formik}
                  id="maxPrice"
                  name="maxPrice"
                  type="number"
                  value={formik?.values?.maxPrice}
                  onBlur={formik.handleBlur}
                  autoComplete="maxPrice"
                  placeholder="maximum price"
                  className="!ring-0 !border !rounded-l-none shadow-sm !border-gray-400 !border-l-0 !p-1"
                  min={0}
                  onKeyPress={handleKeyPress} // Attach key press handler
                />
              </div>
              <div
                style={{
                  height: !(formik.touched?.maxPrice && formik.errors?.maxPrice)
                    ? "40px"
                    : "0px",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t-2 sm2:flex">
        <div className="w-1/2 flex items-center">
          <label className="font-semibold block text-gray-700 mr-[30px] xs2:mr-[52px]">
            Bedrooms
          </label>
          <div className="my-2 w-36">
            <SelectField
              formik={formik}
              label=""
              setFieldValue={formik?.setFieldValue}
              name="bed"
              isSearchable={false}
              options={bedTypeOptions}
              onBlur={formik.handleBlur}
              placeholder="Select Bed"
              autoFocus={true}
            />
          </div>
        </div>
        <div className="w-1/2 flex items-center">
          <label className="font-semibold block text-gray-700 mr-[30px] xs2:mr-[52px]">
            Bathrooms
          </label>
          <div className="my-2 w-36">
            <SelectField
              formik={formik}
              label=""
              setFieldValue={formik?.setFieldValue}
              name="bath"
              isSearchable={false}
              options={bathTypeOptions}
              onBlur={formik.handleBlur}
              placeholder="Select Bath"
              autoFocus={true}
            />
          </div>
        </div>
      </div>
      <div className="mt-3 flex flex-row-reverse sm:flex-nowrap flex-wrap space-y-2 sm:space-y-0">
        <SpinnerButton
          className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
          title={"Save"}
          action={() => formik.handleSubmit()}
          type={"submit"}
          label="Save"
          // loading={isLoading}
          isDisable={!formik.dirty}
        />
        <button
          type="button"
          className=" w-full justify-center rounded bg-gray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-gray-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:ml-3 sm:w-auto"
          onClick={() => {
            formik.handleReset();
            setIsOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
