/* eslint-disable import/no-anonymous-default-export */
export default function mentionStyle(isGreen = true) {
  return {
    control: {
      backgroundColor: "#f3f4f6",
      fontSize: 14,
      // color: "#000",
      borderRadius: "0.675rem", // Rounded corners
      boxShadow: "none", // Remove the box shadow

      borderColor: "#ccc", // Default border color
      "&:focus": {
        borderColor: "#000", // Black border color on focus
      },
    },

    "&multiLine": {
      control: {
        minHeight: 60,
        lineHeight: "normal", //consistent line height
      },
      highlighter: {
        padding: 9,
        border: "1px solid transparent",
        lineHeight: "normal", //consistent line height
      },
      input: {
        padding: 9,
        border: "1px solid silver",
        lineHeight: "normal", //consistent line height
      },
    },

    "&singleLine": {
      display: "inline-block",
      width: 180,
      lineHeight: "normal", //consistent line height

      highlighter: {
        padding: 1,
        border: "2px inset transparent",
        lineHeight: "normal", //consistent line height
      },
      input: {
        padding: 1,
        border: "2px inset",
        lineHeight: "normal", //consistent line height
      },
    },

    suggestions: {
      list: {
        backgroundColor: "#fff",
        border: "1px solid rgba(0, 0, 0, 0.15)",
        fontSize: 14,
        padding: 6,
        fontWeight: 300,
        marginBottom: "10px",
        maxHeight: "200px",
        overflowY: "scroll",
        overflowX: "hidden",
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
        "&focused": {
          backgroundColor: isGreen ? "#4ade80" : "#fb923c",
        },
      },
    },
  };
}
