import { EyeIcon, HeartIcon, HomeIcon } from "@heroicons/react/24/solid";
import { zilliowIcon } from "Assets/icons/SvgIcons";
import { isValidArray, showLeadDateFormat } from "Modules/util";
import propertyPlaceholder from "Assets/Images/PropertyImage/propertyPlaceholder.png";

export default function PropertyCard(props) {
  const { data } = props;
  return (
    <div className="flex flex-col sm:flex-row text-xs text-gray-700 sm:space-x-1.5 rounded-lg p-2 m-2 shadow-md border bg-gray-100">
      <div className="w-full sm:w-20 sm:h-12">
        <img
          src={isValidArray(data?.image) ? data?.image : propertyPlaceholder}
          className="w-full h-full object-cover rounded-md"
          alt="Property"
        />
      </div>
      <div className="w-full flex flex-col sm:flex-row justify-between mt-2 sm:mt-0">
        <div className="w-full sm:!w-[44%]">
          {data?.isFromZillow ? (
            <span
              dangerouslySetInnerHTML={{ __html: data?.mapRedirect }}
              className=" font-semibold text-blue-500 hover:underline hover:cursor-pointer"
            />
          ) : (
            <a
              href={data?.mapRedirect}
              target="_blank"
              rel="noopener noreferrer"
              className="font-medium text-blue-500 hover:underline hover:cursor-pointer break-words"
            >
              {data?.address}
            </a>
          )}

          <div className="flex space-x-1 mt-1">
            {data?.bathroom && (
              <label className="block">
                <b>{data?.bathroom}</b> Baths
              </label>
            )}
            {data?.bedroom && (
              <>
                {" "}
                <label className="block">|</label>
                <label className="block">
                  <b>{data?.bedroom}</b> Beds
                </label>
              </>
            )}
          </div>
        </div>

        {/* Price and Icons */}
        {data?.price && (
          <span className="flex sm:flex-col font-semibold text-green-500 mt-2 sm:mt-0 sm:text-right">
            {data?.price}
            {data?.isFromZillow && (
              <span className="flex justify-center ml-2 sm:mt-1 sm:ml-0">
                {zilliowIcon}
              </span>
            )}
          </span>
        )}
        <div className="w-full sm:!w-[35%] mt-2 sm:mt-0">
          {data?.listingType && (
            <span className="flex space-x-1">
              <HomeIcon className="w-4 h-4 text-orange-500 shrink-0" />
              <span className="font-semibold">{data?.listingType}</span>
            </span>
          )}
          {data?.isWishListed && (
            <span className="flex space-x-1 mt-1">
              <HeartIcon className="w-4 h-4 text-red-500 shrink-0" />
              <span className="font-semibold">Wishlisted</span>
            </span>
          )}
          {data?.date && (
            <span className="flex space-x-1 mt-1">
              <EyeIcon className="w-4 h-4 text-orange-500 shrink-0" />
              <label className="font-semibold">
                {data?.date ? `on ${showLeadDateFormat(data?.date)}` : "-"}
              </label>
            </span>
          )}
          {data?.totalViews && (
            <span className="flex space-x-1 mt-1">
              <label className="font-medium">
                Total Views : {data?.totalViews}
              </label>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
