import React, { useEffect, useState } from "react";
import { useGetPropertySearchesMutation } from "Redux/Leads/PropertySearch/propertySearch";
import PropertySearchCard from "./PropertySearchCard";
import { isValidArray } from "Modules/util";
import notFound from "Assets/icons/notFound.svg";
import Skeleton from "Components/Common/Fields/Skeleton";

export default function PropertySearch({
  lead,
  canDrop,
  setIsRefetchPropertySearch,
  isRefetchPropertySearch,
}) {
  // State variables
  const [propertyList, setPropertyList] = useState([]); // List of saved property searches
  const [isPropertyLoading, setIsPropertyLoading] = useState(false); // Loading state for properties
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    leadId: lead?.leadResponse?.[0]?._id, // Lead ID to fetch properties for
  });
  const [totalRecords, setTotalRecords] = useState(100);
  const [getProperty, { isLoading }] = useGetPropertySearchesMutation(); // API hook to fetch saved property searches

  // Function to handle API call for fetching saved property searches
  const handleProperty = async (filterParams) => {
    try {
      setIsPropertyLoading(true);
      const response = await getProperty(filterParams);

      const savedSearches =
        response?.data?.data?.getSavedPropertySearchesByLeadId?.savedSearches;
      const totalCount =
        response?.data?.data?.getSavedPropertySearchesByLeadId?.totalCount;

      if (isValidArray(savedSearches)) {
        setPropertyList((prev) => [...prev, ...savedSearches]); // Append new searches to the list
        setTotalRecords(totalCount); // Update total record count
      }
    } catch (error) {
      console.error("Error fetching property searches:", error);
    } finally {
      setIsPropertyLoading(false);
    }
  };

  // Infinite scroll handler
  const handleOnScroll = async (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target; // Access scroll properties correctly
    // Check if scrolled to the bottom and more records are available
    if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
      setFilter((filter) => ({
        ...filter,
        page: filter?.page + 1,
      }));
      const currentRecord = filter?.page * filter?.limit;
      if (totalRecords >= currentRecord) {
        handleProperty({
          ...filter,
          page: filter?.page + 1,
          leadId: lead?.leadResponse?.[0]?._id,
        });
      }
    }
  };

  // Effect to fetch data when `isRefetchPropertySearch` or `leadId` changes
  useEffect(() => {
    if (isRefetchPropertySearch && lead?.leadResponse?.[0]?._id) {
      setIsRefetchPropertySearch(false); // Reset refetch flag
      const initialFilter = {
        page: 1,
        limit: 10,
        leadId: lead?.leadResponse?.[0]?._id,
      };

      setFilter(initialFilter); // Reset filter
      setTotalRecords(100); // Reset total records
      setPropertyList([]); // Reset property list
      handleProperty(initialFilter); // Fetch initial searches
    }
  }, [isRefetchPropertySearch, lead?.leadResponse?.[0]?._id]);

  return (
    <div
      className={`leadDeatils-card-body !h-[390px] rounded-b-2xl overflow-y-auto py-2 px-1 ${
        canDrop && "!bg-orange-300 opacity-85"
      }`}
      onScroll={(e) => handleOnScroll(e)} // Attach scroll event handler
    >
      {isValidArray(propertyList) ? (
        propertyList?.map((searchProperty, index) => (
          <PropertySearchCard key={index + 1} searchProperty={searchProperty} />
        ))
      ) : (
        <>
          {isPropertyLoading ? (
            <Skeleton className="my-1" height={"60px"} counter={3} />
          ) : (
            <>
              <div className="flex justify-center pt-[100px]">
                <img
                  alt="edit-icon"
                  src={notFound}
                  className="w-72 grayscale "
                  title={"Move Segment"}
                />
              </div>
              <span className="flex justify-center">
                <label className="font-semibold text-gray text-lg">
                  No Property Found 😔
                </label>
              </span>
            </>
          )}
        </>
      )}
      {/* Render loader at the bottom during data fetching */}
      {isPropertyLoading && (
        <Skeleton className="my-1" height={"60px"} counter={3} />
      )}
    </div>
  );
}
