import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a Notes object using serverApi.injectEndpoints
const Notes = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    addNote: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.addNote(payload),
        };
      },
      invalidatesTags: ["NotesByLeadId", "HistoryByLeadId", "LeadList"],
    }),
    deleteNote: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.deleteNote(payload),
        };
      },
      invalidatesTags: ["NotesByLeadId", "HistoryByLeadId", "LeadList"],
    }),
    getNoteByLeadId: builder.query({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.getNoteByLeadId(payload),
        };
      },
      providesTags: ["NotesByLeadId", "HistoryByLeadId"],
    }),
    editNote: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.editNote(payload),
        };
      },
      invalidatesTags: ["NotesByLeadId", "HistoryByLeadId", "LeadList" ,"Lead"],
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useGetNoteByLeadIdQuery,
  useAddNoteMutation,
  useDeleteNoteMutation,
  useEditNoteMutation
} = Notes;
