export const gqlBody = {
  GetPropertyVisitsByLeadId(payload) {
    return {
      query: `query GetPropertyVisitsByLeadId($input: GetPropertyVisitsInput) {
          getPropertyVisitsByLeadId(input: $input) {
            type
            message
            code
            properties {
              listingType
              price
              address
              mapRedirect
              bedroom
              bathroom
              image
              leadId
              userId
              date
              isFromZillow
              totalViews
              isWishListed
              _id
              LotSizeAcres
            }
            totalCount
          }
        }`,
      variables: { input: payload },
    };
  },
  GetPropertySearchesByLeadId(payload) {
    return {
      query: `query GetSavedPropertySearchesByLeadId($input: getSavedPropertySearchesInput) {
          getSavedPropertySearchesByLeadId(input: $input) {
            type
            message
            code
            savedSearches {
              search
              type
              minimum
              maximum
              bedrooms
              bathrooms
              homeType
              leadId
            }
            totalCount
          }
        }`,
      variables: { input: payload },
    };
  },
  addPropertySearchEAlert(payload) {
    return {
      query: `mutation AddPropertySearchEAlert($input: AddPropertySearchEAlertInput!) {
      addPropertySearchEAlert(input: $input) {
        type
        message
        code
      }
    }`,
      variables: { input: payload },
    };
  },
};
