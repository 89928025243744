import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import TextArea from "Components/Common/Fields/TextArea";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import { isUniqueMentionsArray } from "Components/Leads/assetData";
import mentionStyle from "Components/Leads/LeadDetails/mentionStyle";
import { useFormik } from "formik";
import {
  handleModalClose,
  isValidArray,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { Fragment, useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useEditNoteMutation } from "Redux/Leads/Notes/notes";
import { useGetAllUsersQuery } from "Redux/user/user";
import * as Yup from "yup";

export default function EditDescriptionAndNotesModal(props) {
  const {
    isOpen,
    setIsOpen,
    isDescription,
    noteId,
    comment,
    isGreen = true,
  } = props;
  useEscapeClose(setIsOpen, false, handleModalClose); // Custom hook to close a modal when the Escape key is pressed.
  const [mentionData, setMentionData] = useState([]);
  const [mentionUser, setMentionUser] = useState([]);
  const [editNote, { isLoading }] = useEditNoteMutation();

  const { data: getAllUsers } = useGetAllUsersQuery({
    search: "",
    page: -1,
  }); // Fetching user data using a query hook

  // Effect to fetch mention data when users are available
  useEffect(() => {
    if (isValidArray(getAllUsers?.data?.users?.response)) {
      setMentionData(
        getAllUsers?.data?.users?.response?.map((user) => {
          return {
            id: user?._id,
            display: user?.name,
          };
        })
      );
    }
  }, [getAllUsers]);

  //Handle edit Note
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      noteId: noteId ?? "",
      comment: comment ?? "",
    },
    validationSchema: Yup.object().shape({
      comment: !isDescription && Yup.string().required("Please Enter Note"),
    }),
    onSubmit: async (values) => {
      if (!isUniqueMentionsArray(mentionUser)) {
        formik.setFieldError("comment", "Enter only unique mentions.");
        return;
      }
      let payload = {
        ...values,
      };
      const res = await editNote(payload);
      const editNoteResponse = res?.data?.data?.editNote;
      try {
        if (isValidObject(editNoteResponse)) {
          const { type, message } = editNoteResponse;
          if (type === "success") {
            showSuccessMsg(message);
            setMentionUser([]);
          } else if (type === "error") {
            showErrorMsg(message);
          }
        } else {
          showErrorMsg("Error occurred while edit note");
        }
      } catch (error) {
        showErrorMsg("Error occurred while edit note");
      }
      setIsOpen(false);
      formik.resetForm();
      setMentionUser([]);
    },
  });
  // Effect to update mention data when users are mentioned
  useEffect(() => {
    if (isValidArray(getAllUsers?.data?.users?.response)) {
      let mentionUserId = mentionUser?.map((user) => user?.id);
      let user = getAllUsers?.data?.users?.response?.map((user) => {
        return {
          id: user?._id,
          display: user?.name,
        };
      });
      setMentionData(() =>
        user?.filter((user) => !mentionUserId?.includes(user?.id))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionUser]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1111]"
          onClose={() => {
            // setIsOpen(false);
            // formik.resetForm();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[700px] border-b-2 py-2 transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5">
                  <div className="mt-1 border-b-2 py-2 px-2 sm:mt-0 flex justify-between items-center">
                    <div className="sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                      >
                        Edit {isDescription ? "Description" : "Note"}
                      </Dialog.Title>
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <form onSubmit={formik?.handleSubmit}>
                    <div className="px-3 my-3">
                      {isDescription ? (
                        <TextArea
                          id="comment"
                          label="Description"
                          name="comment"
                          formik={formik}
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="description"
                          placeholder="Edit Description"
                          rows={6}
                          // disabled={!isEditPermisstion}
                        />
                      ) : (
                        <div className="w-full">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Note
                          </label>
                          <MentionsInput
                            className="mentions border border-gray-300 rounded-md  focus:outline-none focus:border-emerald-500 focus:text-black "
                            value={formik?.values?.comment}
                            onChange={(
                              e,
                              newValue,
                              newPlainTextValue,
                              mentions
                            ) => {
                              setMentionUser(mentions);
                              formik?.setFieldValue(
                                "comment",
                                e?.target?.value
                              );
                            }}
                            placeholder="Edit Note"
                            style={mentionStyle(false)}
                          >
                            <Mention
                              className="mentions__mention mentions__mention"
                              data={mentionData}
                              style={{
                                color: isGreen ? "#15803d" : "#fb923c",
                                textDecoration: "underline",
                                padding: "0px 0px",
                              }}
                              displayTransform={(id, display) => `@${display}`}
                            />
                          </MentionsInput>
                          {formik?.touched?.comment &&
                          formik?.errors?.comment ? (
                            <div className="text-red-500 text-sm mt-1">
                              {formik?.errors?.comment}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>

                    <div className="mt-3 flex flex-row-reverse sm:flex-nowrap flex-wrap space-y-2 sm:space-y-0">
                      <SpinnerButton
                        className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                        title={"Submit"}
                        action={() => {}}
                        type={"submit"}
                        loading={isLoading}
                      />
                      <button
                        type="button"
                        className=" w-full justify-center rounded bg-gray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-gray-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:ml-3 sm:w-auto"
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
