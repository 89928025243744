import { isValidArray } from "Modules/util";
import React from "react";

export default function PropertySearchCard(props) {
  const { searchProperty } = props;

  return (
    <div className="text-xs rounded-lg p-2 m-2 shadow-md border bg-gray-100">
      <div className="xs2:flex justify-between">
        <span className="text-sm font-semibold max-w-70 truncate">
          {searchProperty?.search}{" "}
          {searchProperty?.search && searchProperty?.type ? "|" : ""}{" "}
          {searchProperty?.type}
          {!(searchProperty?.search && searchProperty?.type) && (
            <span className="!font-normal opacity-55">No search query</span>
          )}
        </span>
        <div
          style={{
            display:
              searchProperty?.search && searchProperty?.type ? "" : "flex",
          }}
        >
          {/* Price*/}
          {searchProperty?.minimum && (
            <span
              className={`flex space-x-1.5 ${
                !(searchProperty?.search && searchProperty?.type) && "mr-2"
              }`}
            >
              <label>Min Price: </label>
              <span className="flex sm:flex-col font-semibold text-green-500 xs2:mt-2 sm:mt-0 sm:text-right">
                ${searchProperty?.minimum}
              </span>
            </span>
          )}
          {searchProperty?.maximum && (
            <span className="flex space-x-1">
              <label>Max Price: </label>
              <span className="flex sm:flex-col font-semibold text-green-500 xs2:mt-2 sm:mt-0 sm:text-right">
                ${searchProperty?.maximum}
              </span>
            </span>
          )}
        </div>
      </div>
      {isValidArray(searchProperty?.homeType) ? (
        <div>
          <span className="font-semibold text-gray-600"> Home Type :</span>{" "}
          {searchProperty?.homeType?.join(", ")}
        </div>
      ) : (
        ""
      )}
      <div className="flex space-x-1">
        {searchProperty?.bedrooms ? (
          <span>
            <b>{searchProperty?.bedrooms}</b> Beds
          </span>
        ) : (
          ""
        )}
        {searchProperty?.bathrooms && (
          <span className="space-x-1">
            {searchProperty?.bedrooms && <span>|</span>}
            <span>
              <b>{searchProperty?.bathrooms}</b> Baths
            </span>
          </span>
        )}
      </div>
    </div>
  );
}
