import { Tab } from "@headlessui/react";
import moveIcon from "Assets/icons/moveIcon.svg";
import { useEffect, useState } from "react";
import PropertyFilterModal from "./PropertyFilterModal";
import PropertyVisit from "./PropertyVisits/PropertyVisit";
import PropertySearch from "./PropertySearches/PropertySearch";

export default function Property(props) {
  const { drag, canDrop, lead, setIsOpenNestModal } = props;
  const [isPropertyFilterModal, setIsPropertyFilterModal] = useState(false);
  const [isRefetchPropertySearch, setIsRefetchPropertySearch] = useState(true);

  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      propertyFilterModal: isPropertyFilterModal,
    }));
  }, [isPropertyFilterModal]);

  return (
    <>
      <PropertyFilterModal
        isOpen={isPropertyFilterModal}
        setIsOpen={setIsPropertyFilterModal}
        lead={lead}
        setIsRefetchPropertySearch={setIsRefetchPropertySearch}
      />
      <div className="w-full sm:!min-w-[475px] rounded-2xl shadow-md ">
        <Tab.Group>
          <div
            className={`leadDeatils-card-header sm:!h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
              canDrop && "!bg-orange-300 opacity-85"
            }`}
          >
            <Tab.List className="flex h-auto xs2:flex-nowrap flex-wrap xs2:space-y-0 space-y-1 xs2:mt-0 mt-1">
              <div className="bg-white w-0.5 h-[24px] xxs:inline-block hidden mr-1"></div>
              <Tab
                className={({ selected }) =>
                  `cursor-pointer px-3 focus:outline-none focus:ring-0 ${
                    selected
                      ? "border-0 rounded-full bg-orange-500 text-white"
                      : "text-slate-200 hover:bg-orange-500 hover:text-white hover:rounded-full"
                  }`
                }
              >
                Property Visits
              </Tab>
              <div className="bg-white w-[1px] h-[24px] mx-2 mt-1 xxs:inline-block hidden"></div>
              <Tab
                className={({ selected }) =>
                  `cursor-pointer px-3 focus:outline-none focus:ring-0 ${
                    selected
                      ? "border-0 bg-orange-500 text-white rounded-full"
                      : "text-slate-200 hover:bg-orange-500 hover:text-white hover:rounded-full"
                  }`
                }
                onClick={() => setIsRefetchPropertySearch(true)}
              >
                Property Searches
              </Tab>
            </Tab.List>
            <div className="flex space-x-2">
              <button
                className="bg-orange-600 text-white px-1.5 rounded-md text-sm font-medium"
                onClick={() => setIsPropertyFilterModal(true)}
              >
                Save Search
              </button>
              <div
                className="bg-orange-600 rounded-md flex items-center p-1.5 h-7 w-7 cursor-move"
                ref={drag}
              >
                <img
                  alt="edit-icon"
                  src={moveIcon}
                  className="h-4 w-4 rounded-md filter invert brightness-0"
                  title={"Move Segment"}
                />
              </div>
            </div>
          </div>
          <Tab.Panels>
            <Tab.Panel>
              {/* Property Visit Body */}
              <PropertyVisit lead={lead} canDrop={canDrop} />
            </Tab.Panel>
            <Tab.Panel>
              {/* Property Search Body */}
              <PropertySearch
                lead={lead}
                canDrop={canDrop}
                setIsRefetchPropertySearch={setIsRefetchPropertySearch}
                isRefetchPropertySearch={isRefetchPropertySearch}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}
