import { Popover } from "@headlessui/react";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import moveIcon from "Assets/icons/moveIcon.svg";
import SpinnerButton from "Components/Common/Buttons/SpinnerButton";
import SelectField from "Components/Common/Fields/SelectField";
import Skeleton from "Components/Common/Fields/Skeleton";
import TextArea from "Components/Common/Fields/TextArea";
import {
  convertCapitalize,
  formatPhoneNumber,
  getOrdinalSuffix,
  getSendItemsOption,
  hasNonEmptyValuesInObj,
  isPermission,
  isValidArray,
  isValidObject,
  removeUndefined,
  showDateAndTimeFormat,
  showErrorMsg,
  showLeadDateTimeFormat,
  showSuccessMsg,
} from "Modules/util";
import { useGetSMSByLeadIdQuery } from "Redux/Leads/SMS/sms";
import {
  useLeadFollowUPMessageGenerateMutation,
  useSendMessageMutation,
} from "Redux/Leads/lead";
import { useGetConfigurationQuery } from "Redux/settings/settingQuery";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import * as Yup from "yup";
import AIAutoButton from "./AIAutoButton";
import AutoFollowUpEditModal from "./AutoFollowUpEditModal";
import Tippy from "@tippyjs/react";

const SMS = (props) => {
  // Pros
  const {
    lead,
    drag,
    canDrop,
    filterData,
    smsboxContainerRef,
    setIsOpenNestModal,
  } = props;

  const [smsData, setSMSData] = useState(); //State for manage SMS list
  // State variables for component logic
  const [id, setId] = useState();
  const [userPhoneList, setUserPhoneList] = useState();
  const [isUpdatePhoneNumber, setIsUpdatePhoneNumber] = useState(true);
  const [isAiReply, setIsAiReply] = useState(
    isValidArray(lead?.leadResponse)
      ? lead?.leadResponse?.[0]?.isAiReply || false
      : false
  );
  const [showOptedOutValidation, setShowOptedOutValidation] = useState(false);
  const [optedOutNumber, setOptedOutNumber] = useState("");
  const [assignAgentWithCallerId, setAssignAgentWithCallerId] = useState([]);
  const smsContainerRef = useRef(null);
  const leadId = lead?.leadResponse?.[0]?._id; // Extract leadId from lead props
  const leadInfo = isValidObject(lead?.leadResponse?.[0])
    ? lead?.leadResponse?.[0]
    : [];
  const [isAutoFollowupEditModal, setIsAutoFollowupEditModal] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState(45); // implement dynamic textarea resizing with max height constraint

  // lead wise sms list query
  const { data: getSMSByLeadId, isLoading: isSMSByLeadIdLoading } =
  useGetSMSByLeadIdQuery(
    {
      _id: leadId,
    },
    { skip: leadId ? false : true }
  );

  const { data, isSuccess } = useGetConfigurationQuery(); // System configuration query

  const [sendMessage, { isLoading: isSendMessageLoading }] =
    useSendMessageMutation(); // Mutation hook for sending SMS

  const [
    reGenerateAutoFollowUpMessage,
    { isLoading: isAutoFollowUpMessageLoading },
  ] = useLeadFollowUPMessageGenerateMutation(); // Mutation hook for generateting auto follow-up sms

  const userPermissions = useSelector((state) => state?.user?.permission); // Get user permissions from Redux store
  const configurationData = isSuccess
    ? data?.data?.getConfiguration?.response
    : {}; // Extract configuration data from API response

  // Created System Number Option
  const SystemNumber = {
    label: `(System) - ${configurationData?.twilio?.TWILIO_SENDER_PHONE_NUMBER}`,
    value: configurationData?.twilio?.TWILIO_SENDER_PHONE_NUMBER,
  };

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phones: userPhoneList?.[0]?.value ?? "",
      fromNo: lead?.agentResponse?.[0]?.agentTwilioNumber ?? "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      fromNo: leadId ? Yup.string().required("From Is Required") : Yup.string(),
      phones: leadId ? Yup.string().required("To Is Required") : Yup.string(),
      // message: Yup.string().required("Message Is Required"),
    }),
    onSubmit: async (values) => {
      const { sort, ...restFilterData } = filterData;
      setIsUpdatePhoneNumber(false);
      let obj = {};
      // Build the object to be sent for SMS based on leadId
      if (leadId) {
        obj = { phones: values?.phones, ids: [leadId], fromNo: values?.fromNo };
      } else {
        obj = { ids: [...id] };
      }
      obj = {
        ...obj,
        message: values?.message,
        isSelectedAll: false,
        isFilter: hasNonEmptyValuesInObj(restFilterData),
        Filter: removeUndefined(restFilterData),
      };

      // Perform the SMS sending mutation
      try {
        const response = await sendMessage(obj);
        if (response?.data?.data?.sendSMS?.type === "success") {
          showSuccessMsg(response?.data?.data?.sendSMS?.message);
          formik?.setFieldValue("message", "");
        } else if (response?.data?.data?.sendSMS?.type === "error") {
          showErrorMsg(response?.data?.data?.sendSMS?.message);
        } else if (isValidArray(response?.data?.errors)) {
          showErrorMsg(response?.data?.errors?.[0]?.message);
        } else {
          showErrorMsg("Error occurred while Sending Message.");
        }
      } catch (error) {
        showErrorMsg("Error occurred while Sending Message.");
      }
      setTimeout(() => {
        setIsUpdatePhoneNumber(true);
      }, 300);
    },
  });

  async function regenerateAutoFollowUpMessage() {
    const payload = {
      leadId: leadId,
    };
    try {
      const response = await reGenerateAutoFollowUpMessage(payload);
      let autoFollowIpResponse =
        response?.data?.data?.leadFollowUPMessageGenerate;
      const { type, message } = autoFollowIpResponse;
      if (type === "success") {
        showSuccessMsg(message);
      } else if (type === "error") {
        showErrorMsg(message);
      } else if (isValidArray(response?.data?.errors)) {
        showErrorMsg(response?.data?.errors?.[0]?.message);
      } else {
        showErrorMsg("Error occurred while generating auto follow-up Message.");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating auto follow-up Message.");
    }
  }

  // Handle optedout value validation
  function checkIfValueInclude(phones, optedOutPhoneNumber) {
    for (let i = 0; i < optedOutPhoneNumber?.length; i++) {
      if (phones?.includes(optedOutPhoneNumber[i])) {
        return true;
      }
    }
    return false;
  }

  // Handle optedout value validation
  function ValidateOptedOutNumbers(contact) {
    let selectedFromNumber = formik?.values?.fromNo;
    let selectedToNumber = formik?.values?.phones;

    let optedOutStatus;

    let numberInfo = contact?.find(
      (contectInfo) => contectInfo?.phone === selectedToNumber
    );

    if (numberInfo?.fromOptedOutNumber?.includes(selectedFromNumber)) {
      optedOutStatus = true;
    } else if (
      numberInfo?.manuallyOptedOutNo &&
      numberInfo?.manuallyOptedOutNo === selectedFromNumber
    ) {
      optedOutStatus = true;
    } else {
      optedOutStatus = false;
    }
    if (optedOutStatus) {
      setShowOptedOutValidation(true);
      setOptedOutNumber(selectedFromNumber);
    } else {
      setShowOptedOutValidation(false);
      setOptedOutNumber("");
    }
  }

  // UseEffect to set SMS list
  useEffect(() => {
    let smsData = getSMSByLeadId?.data?.getSMSByLeadId?.smsResponse;
    if (!isSMSByLeadIdLoading) {
      if (isValidArray(smsData)) {
        setSMSData(smsData);
      } else {
        setSMSData([]);
      }
    }
  }, [getSMSByLeadId]);

  // UseEffect to update userPhoneList when lead data changes
  useEffect(() => {
    if (isValidArray(lead?.leadResponse)) {
      let phone = lead?.leadResponse[0]?.phone;
      let name = lead?.leadResponse[0]?.firstName;
      if (isUpdatePhoneNumber) {
        setUserPhoneList(getSendItemsOption(phone, name));
      }
    } else {
      if (isUpdatePhoneNumber) {
        setUserPhoneList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId, lead]);

  // UseEffect to manage Initial value of AI Toggle Button
  useEffect(() => {
    if (isValidArray(lead?.leadResponse)) {
      let AiToggleButtonInitialState =
        lead?.leadResponse?.[0]?.isAiReply || false;
      setIsAiReply(AiToggleButtonInitialState);
    }
  }, [lead]);

  // UseEffect to scroll to the bottom when SMS are updated
  useEffect(() => {
    // Added minor delay which gives times to load a Dom
    const scrollTimeout = setTimeout(() => {
      if (smsContainerRef?.current) {
        smsContainerRef.current.scrollTop =
          smsContainerRef.current.scrollHeight;
      }
    }, 100);
    return () => clearTimeout(scrollTimeout);
  }, [
    smsData,
    formik?.values?.phones,
    formik?.values?.fromNo,
    leadInfo?.isFollowUp,
  ]);

  // useEffect for close nested modal on Escape key press
  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      autoFollowupEditModal: isAutoFollowupEditModal,
    }));
  }, [isAutoFollowupEditModal]);

  // UseEffect to manage opted out number validation
  useEffect(() => {
    if (isValidArray(lead?.leadResponse)) {
      let optedOutPhoneNumber = lead?.leadResponse[0]?.contact
        ?.filter((phone) => phone?.smsStatus === "Cannot Be Texted")
        .map((phone) => phone?.phone);
      setShowOptedOutValidation(
        checkIfValueInclude(formik?.values?.phones, optedOutPhoneNumber)
      );
      ValidateOptedOutNumbers(lead?.leadResponse[0]?.contact);
    }
  }, [formik?.values?.phones, formik?.values?.fromNo, lead?.leadResponse]);

  useEffect(() => {
    if (
      isValidArray(lead?.agentResponse) &&
      lead?.agentResponse?.[0]?.agentTwilioNumber
    ) {
      let assignAgentWithCallerId = lead?.agentResponse.map((agent) => {
        return {
          label: `(${agent?.name}) - ${formatPhoneNumber(
            agent?.agentTwilioNumber
          )}`,
          value: agent?.agentTwilioNumber,
        };
      });

      setAssignAgentWithCallerId(assignAgentWithCallerId);
    } else {
      setAssignAgentWithCallerId([]);
    }
  }, [lead?.agentResponse]);

  function getFollowUpMessage() {
    const count = leadInfo?.followUpCount + 1;
    let message = `${count}/5 Auto Follow-up on `;
    if (count > 5) {
      message = "5/5 Auto Follow-ups sent, a task will be created at ";
    }
    return message;
  }

  return (
    <div
      className="sm:min-w-[475px] w-full shadow-md rounded-2xl"
      ref={smsboxContainerRef}
    >
      <div
        className={`bg-green-400 !h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
          canDrop && "!bg-green-300 opacity-85"
        }`}
      >
        <div className="space-x-2 flex items-center ">
          <div className="flex">
            <div className="bg-white w-0.5"></div>
            {/* Title */}
            <div className="text-white pl-[7px] font-medium">SMS</div>
            <span className="text-black font-normal text-xs flex items-center ml-2">
              ( Unresponsive Count:{" "}
              {getSMSByLeadId?.data?.getSMSByLeadId?.unresponsiveSMSCount ?? "-"} )
            </span>
          </div>
        </div>
        {/* MoveIcon */}
        <div
          className="bg-green-600  rounded-md flex items-center p-1.5 h-7 w-7 cursor-move"
          ref={drag}
        >
          <img
            alt="edit-icon"
            src={moveIcon}
            className="filter invert brightness-0 h-4 w-4 rounded-md"
            title={"Move Segment"}
          />
        </div>
      </div>
      <AutoFollowUpEditModal
        isOpen={isAutoFollowupEditModal}
        setIsOpen={setIsAutoFollowupEditModal}
        message={leadInfo?.followUpMessage}
        leadId={leadId}
      />
      <div
        className={`leadDeatils-card-body xs5:h-[390px] rounded-b-2xl ${
          canDrop && "!bg-green-300 opacity-85"
        }`}
      >
        {/* Message Container */}
        <div
          className={`${
            isPermission(userPermissions, "send_sms")
              ? `${
                  optedOutNumber !== "" &&
                  formik?.values?.fromNo === optedOutNumber
                    ? "!h-[205px]"
                    : "xs5:h-[225px] xxs:h-[300px] h-[280px]"
                }`
              : `${
                  optedOutNumber !== "" &&
                  formik?.values?.fromNo === optedOutNumber
                    ? "!h-[216px]"
                    : "!h-[236px]"
                }`
          } overflow-y-auto space-y-3 py-2 px-1 scrollbar-green`}
          style={isPermission(userPermissions, "send_sms") &&
            !(optedOutNumber !== "" && formik?.values?.fromNo === optedOutNumber)
              ? {height: `${273 - textAreaHeight}px`}
              : {}}
          ref={smsContainerRef}
        >
          {isSMSByLeadIdLoading ? (
            <Skeleton counter={3} height={"60px"} className="my-3" />
          ) : isValidArray(smsData) ? (
            smsData?.map((sms, index) => (
              <div
                key={index}
                className={`m-2 p-2 bg-gray-100 shadow-md rounded-lg text-xs space-y-2 ${
                  sms?.status === "received"
                    ? "xs:mr-10 mr-7 my-3 bg-gray-100"
                    : "xs:ml-10 ml-7 !bg-green-100"
                }`}
              >
                <div className="flex justify-between sm:flex-row flex-col sm:space-y-0 space-y-1">
                  <div className="flex text-gray space-x-2 xs:flex-row flex-col xs:space-y-0 space-y-1">
                    <span className="flex space-x-1">
                      <label>From:</label>
                      <label className="flex font-bold">
                        <PhoneArrowUpRightIcon className="w-3 text-green-600 mr-1" />
                        {formatPhoneNumber(sms?.from)}
                      </label>
                    </span>
                    <span className="flex space-x-1">
                      <label>To:</label>
                      <label className="flex font-bold">
                        {" "}
                        <PhoneArrowDownLeftIcon className="w-3 text-green-600 mr-1" />{" "}
                        {formatPhoneNumber(sms?.to) || "-"}
                      </label>
                    </span>
                  </div>
                  <div className="text-gray">
                    {showLeadDateTimeFormat(sms?.createdAt)}
                  </div>
                </div>

                <div className="flex justify-between xxs:flex-row flex-col">
                  <div className="xs:w-[70%] w-full break-all space-x-2 !break-normal">
                    <label className="text-gray">Message:</label>
                    <label>{sms?.message}</label>
                  </div>
                  <div className="flex flex-col justify-center space-y-2 xxs:w-[25%] w-full xxs:mt-0 mt-1">
                    {sms?.status !== "received" && sms?.type ? (
                      <span
                        className={`flex items-center justify-center text-xs font-semibold bg-green-200 px-2 py-1 rounded-md border border-green-600 `}
                      >
                        {sms?.type === "byDrip" || sms?.type === "byOpenAI"
                          ? "By Drip"
                          : sms?.type === "direct"
                          ? "By Direct"
                          : sms?.type === "import"
                          ? "Import"
                          : sms?.type === "autoFollowUp"
                          ? "Auto Follow-up"
                          : ""}
                      </span>
                    ) : (
                      <></>
                    )}

                    <span
                      className={`flex items-center justify-center text-xs ${
                        sms?.status === "failed" ||
                        sms?.status === "undelivered"
                          ? "text-red-500"
                          : "text-green-600"
                      }`}
                    >
                      {sms?.status === "failed" ||
                      sms?.status === "undelivered" ? (
                        <Popover className="relative">
                          <Popover.Button>
                            <InformationCircleIcon className="text-red-500 hover:text-red-600 w-5" />
                          </Popover.Button>
                          <Popover.Panel className="absolute -top-14 -left-80 z-50 bg-white p-3 shadow-lg rounded-md w-[315px]">
                            <p className="text-gray-700 max-h-52 overflow-y-auto">
                              {sms?.errorMessage + " "}
                            </p>
                            <Link
                              className="text-red-500 hover:underline hover:text-red-600"
                              target="_blank"
                              to={sms?.errorURL}
                            >
                              Click here to learn more about ERROR:
                              {sms?.errorCode && <> {sms.errorCode}</>}
                            </Link>
                          </Popover.Panel>
                        </Popover>
                      ) : sms?.status === "failed" ? (
                        <XCircleIcon className="text-red-500 w-5 h-5" />
                      ) : (
                        <CheckCircleIcon className="text-green-600 w-5 h-5" />
                      )}
                      {sms?.status && convertCapitalize(sms?.status)}
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex justify-center text-gray-600 text-xs h-[185px]">
              <span className="self-center">No SMS history available.</span>
            </div>
          )}
          <div className="flex justify-center ">
            {showOptedOutValidation && (
              <span className="bg-gray-200 p-2 rounded text-gray-600 text-xs mx-8">
                <b>ATTENTION:</b> This lead has opted out of communication. You
                may still read previous texts.
              </span>
            )}
          </div>
          <Tippy
            allowHTML={true}
            interactive={true}
            placement={"bottom"}
            animateFill={true}
            animation={"scale"}
            maxWidth={"600px"}
            content={
              leadInfo?.followUpCount + 1 <= 5 ? (
                <div className="!text-wrap max-w-[600px]">
                  {isAutoFollowUpMessageLoading ? (
                    <span className="flex">
                      Preview:{" "}
                      <span className="flex w-full">
                        <label className="ml-2">Generating Preview</label>
                        <span className="mt-0.5">
                          <PulseLoader size={3} color="#ffffff" />
                        </span>
                      </span>
                    </span>
                  ) : (
                    <div>Preview:</div>
                  )}
                  <div
                    contentEditable={false}
                    className="text-justify p-2"
                    style={{ whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{
                      __html: leadInfo?.followUpMessage,
                    }}
                  ></div>
                  <div className="flex justify-end mt-3 space-x-2">
                    <button
                      className="px-2 py-1 border border-white disabled:cursor-not-allowed"
                      onClick={() => regenerateAutoFollowUpMessage()}
                      disabled={isAutoFollowUpMessageLoading}
                    >
                      Regenerate Preview
                    </button>
                    <button
                      className="px-2 py-1 border border-white"
                      onClick={() => setIsAutoFollowupEditModal(true)}
                    >
                      Edit Preview
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )
            }
          >
            <div className="flex justify-center ">
              {leadInfo?.isFollowUp && (
                <span className="bg-green-200 p-2 rounded text-gray-600 text-xs mx-4">
                  <b>Auto Follow-up: </b>
                  {getFollowUpMessage()}
                  {showDateAndTimeFormat(leadInfo?.followUpScheduledDateTime)}
                </span>
              )}
            </div>
          </Tippy>
        </div>
        {isPermission(userPermissions, "send_sms") && (
          <div className="px-2 pb-2 border-t border-gray-200 rounded-lg">
            <div>
              <div className="grid xs:grid-cols-2 grid-cols-1 w-full">
                {/* From Number */}
                <div className="">
                  <SelectField
                    formik={formik}
                    // options={
                    //   agentsWithPhone && [SystemNumber, ...agentsWithPhone]
                    // }
                    options={assignAgentWithCallerId ?? []}
                    name="fromNo"
                    label="From"
                    isSearchable={false}
                    setFieldValue={formik.setFieldValue}
                    onBlur={formik.handleBlur}
                    placeholder={"Select From Number"}
                    onChange={formik.handleChange}
                    labelClassname={"text-xs"}
                    className={"text-xs !mt-1"}
                    optedOutNumber={optedOutNumber}
                    isLabelValidation={true}
                  />
                </div>
                {/* To Number */}
                <div className="xs:px-2 px-0">
                  <SelectField
                    formik={formik}
                    options={userPhoneList}
                    name="phones"
                    placeholder={"Select To Number"}
                    label="To"
                    isSearchable={false}
                    setFieldValue={formik.setFieldValue}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    labelClassname={"text-xs"}
                    className={"text-xs !mt-1"}
                    isLabelValidation={true}
                  />
                </div>
              </div>
            </div>
            {optedOutNumber !== "" &&
              formik?.values?.fromNo === optedOutNumber && (
                <span className="text-red-500 text-xs font-semibold h-min m-0">
                  (Number is Opted-Out)
                </span>
              )}
            <AIAutoButton
              formik={formik}
              isAiReply={isAiReply}
              smsData={smsData}
              setIsAiReply={setIsAiReply}
              leadId={leadId}
              lead={lead}
              showOptedOutValidation={showOptedOutValidation}
              setIsOpenNestModal={setIsOpenNestModal}
              setTextAreaHeight={setTextAreaHeight}
            />
            <div className="flex">
              {/* Message Box */}
              <TextArea
                id="text-message"
                label=""
                name="message"
                formik={formik}
                className="border-1 border-gray-300 bg-gray-100 placeholder-gray-700 !text-xs h-[45px]"
                rows={2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="message"
                placeholder="Enter Message"
                disabled={isSendMessageLoading || showOptedOutValidation}
                validation={false}
                isResizeAble={true}
                setTextAreaHeight={setTextAreaHeight}
              />
              <div className="w-16 h-8 ml-3 my-auto">
                {/* Send Button */}
                <SpinnerButton
                  type="submit"
                  label="Send"
                  title={
                    showOptedOutValidation
                      ? "Number Is Opted-Out"
                      : showOptedOutValidation || isSendMessageLoading
                      ? ""
                      : !formik?.values?.message
                      ? "Enter Message"
                      : isValidObject(formik?.errors)
                      ? ""
                      : !formik?.values?.fromNo || !formik?.values?.phones
                      ? ""
                      : "Send Message"
                  }
                  action={() => formik?.handleSubmit()}
                  className="btn-sms rounded-lg text-sm font-medium disabled:cursor-not-allowed disabled:bg-gray-400 disabled:opacity-75"
                  isLoading={isSendMessageLoading}
                  isDisable={
                    showOptedOutValidation || isSendMessageLoading
                      ? true
                      : isValidObject(formik?.errors)
                      ? true
                      : !formik?.values?.message
                      ? true
                      : !formik?.values?.fromNo || !formik?.values?.phones
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SMS;
