import { phoneFormat } from "Components/Common/formatter";
import * as Yup from "yup";

export const showFieldError = (value) => (
  <span className="text-red-500 text-sm mt-1">{value}</span>
);

export const roleForm = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    permissions: Yup.array().min(1, "At least one permission is required"),
  });
};

export const settingsForm = () => {
  return Yup.object().shape({
    timezone: Yup.string().required("Timezone is required"),
    emailFrom: Yup.string()
      .required("Email From is required")
      .matches(
        /^[^.@][^@]*@[^.@]+\.[^.@]+$/,
        "Please enter a valid Email From."
      ),
    fromName: Yup.string().required("From Name is required"),
    accountSId: Yup.string().required("Account SID is required"),
    authToken: Yup.string().required("Auth Token is required"),
    host: Yup.string().required("Host is required"),
    port: Yup.string().required("Port is required"),
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
    senderPhoneNumber: Yup.string()
      .required("Sender Phone Number is required")
      .matches(phoneFormat, "Please Enter Valid Sender Phone Number"),
    dripStartTime: Yup.string().required("Start Time is required"), // Validation for dripStartTime - it is required
    notificationEmail: Yup.string().matches(
      /^[^.@][^@]*@[^.@]+\.[^.@]+$/,
      "Please enter a valid Email"
    ),
    dripEndTime: Yup.string() // Custom test to check if dripEndTime is not the same as dripStartTime
      .required("End time is required") // Validation for dripEndTime - it is required
      .test(
        "is-greater",
        "End time should be greater than Start time",
        function (value) {
          const { dripStartTime } = this.parent;
          return (
            new Date(`1970-01-01 ${value}`) >
            new Date(`1970-01-01 ${dripStartTime}`)
          );
        }
      ) // Custom test to check if dripEndTime is greater than dripStartTime
      .test(
        "not-same",
        "End time should not be the same as Start time",
        function (value) {
          const { dripStartTime } = this.parent;
          return value !== dripStartTime;
        }
      ),

    openAIKey: Yup.string().required("openAIKey Id is required"), // Validation for openAIKey - it is required
    client_secret: Yup.string().required("Google App Secret Id is required"), // Validation for  client_secret - it is required
    client_id: Yup.string().required("Google App Client Id is required"), // Validation for  client_id - it is required
  });
};

export const SMSTemplateForm = () => {
  return Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .max(100, "title must be at most 100 characters"),
    body: Yup.string().required("Body is required"),
  });
};

export const EmailTemplateForm = () => {
  return Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .max(100, "title must be at most 100 characters"),
    subject: Yup.string()
      .required("Subject is required")
      .max(100, "Subject must be at most 100 characters"),
    body: Yup.string()
      .notOneOf(["", "<p><br></p>"], "Body is required")
      .required("Body is required")
      .test("is-not-empty", "Body is required", (value) => {
        // Check if the value is null or only spaces
        const doc = new DOMParser().parseFromString(value?.trim(), "text/html");
        return doc?.body?.textContent?.trim()?.length > 0;
      }),
  });
};

export const tagForm = () => {
  return Yup.object().shape({
    tagTitle: Yup.string()
      .required("Title is required")
      .max(100, "Title must be at most 100 characters"),
  });
};

export const categoryForm = () => {
  return Yup.object().shape({
    title: Yup.string().required("Title is required"),
    color: Yup.string().required("Background Color is required"),
    textColor: Yup.string().required("Text color is required"),
  });
};

export const bucketForm = (durationValidation) => {
  return Yup.object().shape({
    title: Yup.string().required("Title is required"),
    duration: Yup.string().required("Duration is required"),
    count: Yup.number()
      .required("Duration is required")
      .min(1, "Duration must be at least 1")
      .integer("Duration should not be decimal.")
      .max(
        durationValidation?.count,
        `Maximum duration for ${durationValidation?.duration}(s) is ${durationValidation?.count}`
      ),
  });
};

export const changePasswordForm = () => {
  return Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required."),
    newPassword: Yup.string()
      .required("New Password is required.")
      .min(6, "New Password must be at least 6 characters")
      .required("New Password is required"),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), null],
        "New Password and Confirm Password must match."
      )
      .required("Confirm Password is required."),
  });
};

export const addTaskValidationForm = () => {
  return Yup.object().shape({
    date: Yup.date().required("Date is required"),
    leadId: Yup.string().required("Lead is required"),
    toDoId: Yup.string().required("Type is required"),
  });
};

export const leadDetailsValidationForm = () => {
  return Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
  });
};

export const addCallLogValidationForm = Yup.object().shape({
  status: Yup.string().required("status are required"),
});

export const addTaskValidationFormInLeadDetails = () => {
  return Yup.object().shape({
    date: Yup.date().required("Date is required"),
    toDoId: Yup.string().required("Type is required"),
  });
};

export const filterSaveForm = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
};
export const dripFormValidation = () => {
  return Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });
};

export const propmtTemplateForm = () => {
  return Yup.object().shape({
    prompt: Yup.string().required("prompt is required"),
  });
};

export const profileValidation = (isLender, isAgent, isAdmin) => {
  return Yup.object().shape({
    firstName: Yup.string().required("First Name Is Required"),
    lastName: Yup.string().required("Last Name Is Required"),
    email: Yup.string().email("Invalid Email").required("Email Is Required"),
    phone: Yup.string()
      .matches(phoneFormat, "Please Enter Valid Phone Number")
      .required("Phone is Required."),
    financialInstitute:
      isLender && Yup.string().required("Financial Institute Is Required"),
    companyName: isAgent && Yup.string().required("Company Name Is Required"),
    appPassword: Yup.string()
      .min(16, "Please enter valid Gmail App password")
      .max(19, "Please enter valid Gmail App password"),
    dripFromEmail: isAdmin && Yup.string().required("Email is required"),
  });
};

export const changeUserPasswordForm = () => {
  return Yup.object().shape({
    newPassword: Yup.string()
      .required("New Password is required.")
      .min(6, "New Password must be at least 6 characters")
      .required("New Password is required"),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), null],
        "New Password and Confirm Password must match."
      )
      .required("Confirm Password is required."),
  });
};

export const nodePrompt = Yup.object().shape({
  prompt: Yup.string().required("Prompt is required"), // Define validation rules for the 'prompt' field
});

export const cloneDripForm = (title) => {
  const isUniqueTitle = (value) => {
    return title?.trim() !== value?.trim();
  };
  return Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .test(
        "is-unique",
        "Drip title already exists. Please enter a unique title.",
        isUniqueTitle
      ),
  });
};

export const dripSettingForm = () => {
  return Yup.object().shape({
    from: Yup.string()
      .matches(/^[a-zA-Z0-9_]+@[a-zA-Z0-9.-]+$/, "From is required")
      .required("From is required"),
  });
};

export const addSavePropertyValidation = () =>
  Yup.object({
    minPrice: Yup.number()
      .min(0, "Minimum price cannot be negative")
      .test(
        "is-less-than-maxPrice",
        "Minimum price must be less than max price",
        function (value) {
          const { maxPrice } = this.parent;
          return (
            value === undefined || maxPrice === undefined || value < maxPrice
          );
        }
      ),
    maxPrice: Yup.number()
      .min(0, "Maximum price cannot be negative")
      .test(
        "is-greater-than-minPrice",
        "Maximum price must be greater than min price",
        function (value) {
          const { minPrice } = this.parent;
          return (
            value === undefined || minPrice === undefined || value > minPrice
          );
        }
      ),
  });
